// Generated by https://quicktype.io

import { DataSet } from '../core/data/interface';

export interface Sector extends DataSet {
  id: number;
  name: string;
  items: SubSector[];
}

export interface SubSector extends DataSet {
  id: number;
  name: string;
}

export type SectorListItem = Omit<Sector, 'items'> & {
  parentId: string | null;
  ownId: string;
};

export const toSector = (input: ApiSector): Sector => ({
  id: input.id,
  name: input.name,
  items:
    input.subsectors instanceof Array && input.subsectors.length
      ? input.subsectors.map(({ id, name }) => ({
          id,
          name,
        }))
      : ([] as SubSector[]),
});

export interface ApiSector {
  id: number;
  name: string;
  parent_id: number;
  subsectors: {
    created_at: null;
    updated_at: null;
    id: number;
    name: string;
    parent_id: number;
  }[];
}
