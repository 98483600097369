import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent {
  public credentialsValid = true;
  public passwordsMatch = true;

  constructor(private auth: AuthService, private router: Router) {}

  changePassword(
    event: Event,
    oldPassword: string,
    newPassword: string,
    verifyPassword: string
  ) {
    event.preventDefault();

    this.passwordsMatch =
      !!newPassword.length && newPassword === verifyPassword;

    this.credentialsValid = !!oldPassword.length && !!newPassword.length;

    if (this.credentialsValid && this.passwordsMatch) {
      this.auth
        .changePassword(oldPassword, newPassword)
        .subscribe((success) => {
          this.credentialsValid = success;
          if (success) {
            this.router.navigateByUrl('/my-lists/home');
          }
        });
    }
  }
}
