import { HttpClient } from '@angular/common/http';
import { CompanyPublication, toPublication } from './company.model';
import { environment } from '@app/environment';
import { DataSourceAdapter } from '../core/data';
import { ApiCompanyPublication } from './api-result.model';

export const getPublicationsSource = (http: HttpClient, companyId: string) =>
  new DataSourceAdapter<CompanyPublication>(
    {
      selectAll: (path, options) => {
        if (
          (!options ||
            typeof options.skip !== 'number' ||
            typeof options.take !== 'number') &&
          !environment.production
        ) {
          console.warn('pagination options missing', { path, options });
          // return throwError(() => new Error('Malformed Request'));
        }
        return http.post<{ totalCount: number; data: ApiCompanyPublication[] }>(
          path,
          {
            method: 'fetchPublication',
            data: {
              companyId,
              page:
                (options &&
                  options.skip &&
                  options.take &&
                  options.skip / options.take) ||
                0,
              resultsPerPage: (options && options.take) || 10,
            },
          }
        );
      },
    },
    {
      path: `${environment.apiBase}/fetchPublication`,
      key: 'business_id',
      postProcess: (data: ApiCompanyPublication[]) => data.map(toPublication),
      pageSize: 10,
    }
  ).asDataSource();
