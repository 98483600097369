export interface APICompanyListResult {
  totalCount: number;
  data: ApiCompanyListItem[];
}

interface ReviewScore {
  comment: string | null;
  company_id: string;
  created_at: string;
  updated_at: string;
  criterion_id: number;
  id: number;
  latest_update_by: string | null;
  latest_update_on: string | null;
  reason_for_exclusion: null | boolean;
  score: null | number;
  users: {
    first_name: null | string;
    last_name: null | string;
  };
}

export interface APIReference {
  created_at: null | string;
  updated_at: null | string;
  tracxn_id: null | string;
  sourcescrub_id: string;
  crunchbase_id: null | string;
  id: number;
  business_id: string;
  date: string;
  mentioned_company_id: string;
  referencing_source_id: string;
  sources: {
    created_at: null | string;
    updated_at: null | string;
    tracxn_id: null | string;
    sourcescrub_id: string;
    crunchbase_id: null | string;
    id: number;
    business_id: string;
    date: string;
    name: string;
  };
}

export interface ApiCompanyListItem {
  business_id: string;
  city: string;
  country_code: string | null;
  created_at: string;
  domain: string;
  facebook_url: string | null;
  founded_at: string;
  id: number;
  icon: string | null;
  legal_name: string | null;
  linkedin_url: string | null;
  long_description: string | null;
  name: string;
  ownership_type: string;
  postal_code: string | null;
  short_description: string;
  state_code: string | null;
  twitter_url: string | null;
  website_url: string | null;
  type: string | null;
  assignees_companies: {
    users: null | {
      email: string;
      first_name: string;
      last_name: string;
    };
  };
  attractivenesses: {
    score: number | null;
  }[];
  review_statuses: {
    comment?: string | null;
    status: string;
  }[];
  review_scores: ReviewScore[];
  employee_details: {
    count: number | null;
    growth_rate: number | null;
  }[];
  people: {
    business_id: string | null;
    employer_id: string;
    email_address: string;
    first_name: string;
    last_name: string;
    job_title: string;
    linked_in_profile_url: string;
    phone_number: string;
    created_at: string;
    updated_at: string;
  }[];
  financial_statements: {
    revenue: number;
    currency_code: string;
  }[];
  companies_latest?: {
    attractiveness: number | null;
    ebitda: number | null;
    ipo_valuation_amount: number | null;
    employee_count: number | null;
    employee_growth_rate: number | null;
    investment_amount: number | null;
    investment_date: string | null;
    revenue: number | null;
    review_comment: string | null;
    review_status: string | null;
    review_reviewer_name: string | null;
    subsector_name: string | null;
    investor_names: string | null;
    investor_names_with_business_ids: { business_id: string; name: string }[];
  };
  companies_subsectors: {
    created_at: string;
    updated_at: string;
    // tracxn_id: string;
    // sourcescrub_id: string;
    // crunchbase_id: string;
    id: number;
    name: string | null;
    subsector_id: number;
    // company_id: string;
    subsectors: {
      created_at: string;
      updated_at: string;
      id: number;
      name: string;
      parent_id: number;
      sector_id: number;
      sectors?: {
        created_at: string;
        updated_at: string;
        id: number;
        name: string;
        parent_id: number;
      };
    };
  }[];
  investments: {
    created_at: string;
    updated_at: string;
    tracxn_id: string;
    sourcescrub_id: string | null;
    crunchbase_id: string | null;
    id: number;
    amount: number;
    business_id: string;
    currency_code: string;
    date: string;
    investee_id: string;
    name: string | null;
    post_money_evaluation: number | null;
    type: string | null;
    investors: {
      created_at: string;
      updated_at: string;
      tracxn_id: string;
      sourcescrub_id: string | null;
      crunchbase_id: string | null;
      id: number;
      // amount: null | number;
      business_id: string | null;
      // currency: string | null;
      // date: string;
      investor_id: string;
      investment_id: string;
      is_lead_investor: boolean;
      companies: ApiCompanies;
    }[];
    facilitators: ApiFacilitator[];
  }[];
  facilitators?: ApiFacilitator[];
  ipos: {
    date: string;
    raised_amount: null | number;
    raised_currency_code: string | null;
    share_amount: null | number;
    share_currency_code: string | null;
    stock_exchange_symbol: string;
    stock_symbol: string;
    valuation_amount: number;
    valuation_currency_code: string;
  }[];
}

export interface ApiFacilitator {
  tracxn_id: string;
  sourcescrub_id: string | null;
  crunchbase_id: string | null;
  id: number;
  business_id: string | null;
  date: string;
  facilitator_id: string;
  investment_id: string;
  companies: ApiCompanies;
}

export interface ApiCompanies {
  created_at: string;
  updated_at: string;
  tracxn_id: string;
  sourcescrub_id: string | null;
  crunchbase_id: string | null;
  assignees_companies: {
    company_id: string;
    user_id: string;
    users: {
      first_name: string;
      last_name: string;
      email: string;
    };
  };
  id: number;
  business_id: string;
  name: string;
  legal_name: string | null;
  city: string;
  postal_code: string | null;
  state_code: string | null;
  country_code: string | null;
  domain: string;
  facebook_url: string | null;
  linkedin_url: string | null;
  twitter_url: string | null;
  founded_at: string;
  is_relevant: null | boolean;
  long_description: string | null;
  publicly_listed: null | boolean;
  similar_companies: null | unknown;
  short_description: string;
  type: string | null;
  orphaned: null | boolean;
  ownership_type: string | null;
  website_url: string | null;
  parent_id: string | null;
  has_alerts: null | boolean;
}

export type ApiCompanyPublication = {
  created_at: string;
  updated_at: string;
  tracxn_id: string;
  sourcescrub_id: string | null;
  crunchbase_id: string | null;
  id: number;
  business_id: string;
  date: string;
  name: string | null;
  type: string | null;
  url: string;
  publicized_company_id: string;
  overwrite_reference: {
    [prop in keyof Omit<
      ApiCompany['publications'][number],
      'overwrite_reference'
    >]?: string;
  };
};

export interface ApiCompany {
  created_at: string;
  updated_at: string;
  tracxn_id: string;
  sourcescrub_id: null | string;
  crunchbase_id: null | string;
  assignees_companies: {
    company_id: string;
    user_id: string;
    users: {
      first_name: string;
      last_name: string;
      email: string;
    };
  };
  companies_latest?: {
    attractiveness: number | null;
    ebitda: number | null;
    ipo_valuation_amount: number | null;
    employee_count: number | null;
    employee_growth_rate: number | null;
    investment_amount: number | null;
    investment_date: string | null;
    revenue: number | null;
    review_comment: string | null;
    review_status: string | null;
    review_reviewer_name: string | null;
    subsector_name: string | null;
    investor_names: string | null;
    investor_names_with_business_ids: { business_id: string; name: string }[];
  };
  id: number;
  icon: string | null;
  business_id: string;
  name: string;
  legal_name: null | string;
  city: string;
  postal_code: null | string;
  state_code: null | string;
  country_code: string;
  domain: string;
  facebook_url: string;
  linkedin_url: string;
  twitter_url: string;
  founded_at: string;
  is_relevant: null | boolean;
  long_description: string;
  publicly_listed: null;
  similar_companies: Partial<ApiCompany>[] | null;
  short_description: string;
  type: null;
  orphaned: null;
  ownership_type: string;
  overwrite_reference: { [prop in keyof ApiCompany]?: string };
  website_url: string;
  has_alerts: null;
  users: {
    created_at: string;
    updated_at: string;
    id: string;
    email: string;
    first_name: string;
    last_name: string;
  };
  parent_id: string | null;
  parent_company: Partial<ApiCompany> | null;
  companies: Partial<ApiCompany> | null;
  comments: ApiCompanyComment[];
  publications: ApiCompanyPublication[];
  references: APIReference[];
  acquisitions_acquisitions_acquirer_idTocompanies: {
    created_at: string;
    updated_at: string;
    tracxn_id: string;
    sourcescrub_id: null;
    crunchbase_id: null;
    id: number;
    amount: number | null;
    acquirer_id: string;
    acquiree_id: string;
    business_id: null;
    currency_code: null;
    date: string;
    companies_acquisitions_acquiree_idTocompanies: Companiescompanies;
    overwrite_reference: {
      [prop in keyof Omit<
        ApiCompany['acquisitions_acquisitions_acquirer_idTocompanies'][number],
        'overwrite_reference'
      >]?: string;
    };
  }[];
  acquisitions_acquisitions_acquiree_idTocompanies: unknown[];
  review_statuses: {
    created_at: string;
    updated_at: string;
    id: number;
    date: string;
    log: string | null;
    reviewed_company_id: string;
    reviewer_id: string | null;
    status: string;
    status_log: string | null;
    comment?: string | null;
    users: {
      created_at: null;
      updated_at: string;
      id: string;
      email: string;
      first_name: string;
      last_name: string;
    };
  }[];
  review_scores: ReviewScore[];
  employee_details: {
    created_at: string;
    updated_at: string;
    tracxn_id: string;
    sourcescrub_id: null;
    crunchbase_id: null;
    id: number;
    business_id: string;
    employer_id: string;
    employee_range: string;
    count: number;
    growth_rate: number;
    date: string;
    overwrite_reference: {
      [prop in keyof Omit<
        ApiCompany['employee_details'][number],
        'overwrite_reference'
      >]?: string;
    };
  }[];
  financial_statements: {
    created_at: string;
    updated_at: string;
    tracxn_id: string;
    sourcescrub_id: string | null;
    crunchbase_id: string | null;
    id: number;
    business_id: string;
    cagr: number;
    company_id: number;
    currency_code: string;
    ebitda: number | null;
    revenue: number;
    revenue_per_employee: number | null;
    revenue_growth_rate: number | null;
    date: string;
    overwrite_reference: {
      [prop in keyof Omit<
        ApiCompany['financial_statements'][number],
        'overwrite_reference'
      >]?: string;
    };
  }[];
  valuations: {
    created_at: string;
    updated_at: string;
    tracxn_id: null;
    sourcescrub_id: null;
    crunchbase_id: null;
    id: number;
    business_id: string;
    company_id: string;
    currency_code: string;
    valuation: number;
    date: string;
    overwrite_reference: {
      [prop in keyof Omit<
        ApiCompany['valuations'][number],
        'overwrite_reference'
      >]?: string;
    };
  }[];
  people: {
    created_at: string;
    updated_at: string;
    tracxn_id: string | null;
    sourcescrub_id: string | null;
    crunchbase_id: string | null;
    id: number;
    business_id: string | null;
    employer_id: string;
    email_address: string;
    first_name: string;
    last_name: string;
    job_title: string;
    linked_in_profile_url: string;
    phone_number: string;
    overwrite_reference: null | {
      first_name?: string;
      last_name?: string;
      email_address?: string;
      job_title?: string;
      phone_number?: string;
      linked_in_profile_url?: string;
    };
  }[];
  companies_subsectors: {
    created_at: string;
    updated_at: string;
    id: number;
    name: string | null;
    subsector_id: number;
    business_id: string | null;
    overwrite_reference: null | { subsector_id: string };
    // company_id: string;
    subsectors: {
      created_at: string;
      updated_at: string;
      id: number;
      name: string;
      parent_id: number;
      sector_id: number;
    };
  }[];
  investments: {
    overwrite_reference: {
      [prop in keyof Omit<
        ApiCompany['investments'][number],
        'overwrite_reference'
      >]?: string;
    };
    created_at: string;
    updated_at: string;
    tracxn_id: string;
    sourcescrub_id: string | null;
    crunchbase_id: string | null;
    id: number;
    amount: number;
    business_id: string;
    currency_code: string;
    date: string;
    investee_id: string;
    name: string | null;
    post_money_evaluation: number | null;
    type: string | null;
    investors: {
      created_at: string;
      updated_at: string;
      tracxn_id: string;
      sourcescrub_id: null;
      crunchbase_id: null;
      id: number;
      amount: number | null;
      business_id: null;
      currency: null | string;
      date: string;
      investor_id: string;
      investment_id: string;
      is_lead_investor: boolean;
      companies: Companiescompanies;
    }[];
    facilitators: Facilitator[];
  }[];
  attractivenesses: {
    created_at: string;
    updated_at: string;
    id: number;
    score: null;
    assessed_company_id: string;
    sub_sector_id: number;
  }[];
  ipos: {
    created_at: string;
    updated_at: string;
    tracxn_id: string;
    sourcescrub_id: null;
    crunchbase_id: null;
    id: number;
    business_id: string;
    date: string;
    investee_id: string;
    raised_amount: null | number;
    raised_currency_code: string | null;
    share_amount: null | number;
    share_currency_code: string | null;
    stock_exchange_symbol: string;
    stock_symbol: string;
    valuation_amount: number;
    valuation_currency_code: string;
    overwrite_reference: {
      [prop in keyof Omit<
        ApiCompany['ipos'][number],
        'overwrite_reference'
      >]?: string;
    };
  }[];
}

export interface ApiCompanyComment {
  created_at: string;
  updated_at: string;
  id: number;
  author_id: string;
  commented_company_id: string;
  date: string;
  text: string;
  users: {
    created_at: string;
    updated_at: string;
    id: string;
    email: string;
    first_name: string;
    last_name: string;
  };
}

export interface Companiescompanies {
  created_at: string;
  updated_at: string;
  tracxn_id: string;
  sourcescrub_id: null;
  crunchbase_id: null;
  assignees_companies: {
    company_id: string;
    user_id: string;
    users: {
      first_name: string;
      last_name: string;
      email: string;
    };
  };
  id: number;
  business_id: string;
  name: string;
  legal_name: null;
  city: string;
  postal_code: null;
  state_code: null;
  country_code: null;
  domain: string;
  facebook_url: string | null;
  linkedin_url: string | null;
  twitter_url: string | null;
  founded_at: string;
  is_relevant: null;
  long_description: string | null;
  publicly_listed: null;
  similar_companies: null;
  short_description: string;
  type: null;
  orphaned: null;
  ownership_type: null;
  website_url: null;
  parent_id: null;
  has_alerts: null;
}

export interface Facilitator {
  created_at: string;
  updated_at: string;
  tracxn_id: string;
  sourcescrub_id: null;
  crunchbase_id: null;
  id: number;
  business_id: string | null;
  date: string;
  facilitator_id: string;
  investment_id: string;
  companies: Companiescompanies;
}

// Generated by https://quicktype.io

export interface APIUserPageVisits {
  id: string;
  user_id: string;
  company_id: string;
  date: string;
  users: {
    created_at: string;
    updated_at: string;
    id: string;
    email: string;
    first_name: string;
    last_name: string;
  };
  companies: {
    created_at: null | string;
    updated_at: null | string;
    business_id_version: string;
    crunchbase_id: null | string;
    sourcescrub_id: null | string;
    tracxn_id: string;
    id: number;
    assignees_companies: {
      company_id: string;
      user_id: string;
      users: {
        first_name: string;
        last_name: string;
        email: string;
      };
    };
    business_id: string;
    city: string;
    country_code: string;
    domain: string;
    facebook_url: null | string;
    founded_at: null | string;
    icon: string;
    is_relevant: boolean;
    legal_name: string;
    linkedin_url: null | string;
    long_description: null | string;
    name: string;
    orphaned: boolean;
    ownership_type: null | string;
    parent_id: null | string;
    postal_code: null | string;
    short_description: string;
    similar_companies: null | string;
    state_code: null | string;
    twitter_url: null | string;
    type: null | string;
    website_url: string;
  };
}

export interface APIDownloadList {
  exportIdentifier: string;
  isComplete: boolean;
  presignedURL?: string;
  filename?: string;
}
