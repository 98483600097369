import { AlertService, DeviceService } from '@app/core';
import {
  Component,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { AlertItem } from '@app/model';
import { EMPTY, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DxListComponent } from 'devextreme-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alert-list',
  templateUrl: './alert-list.component.html',
  styleUrls: ['./alert-list.component.scss'],
})
export class AlertListComponent implements OnChanges {
  @Input() alerts: AlertItem[] = [];
  @Output() alertClicked = new EventEmitter<void>();
  @ViewChild('list') list!: DxListComponent;

  public unreadAlerts: string[] = [];
  public filter: {
    showUnread: boolean | null | undefined;
    selectedTab: number;
  } = {
    showUnread: false,
    selectedTab: 0,
  };
  public visibleAlerts: AlertItem[] = [];

  public isMobile = this.deviceService.isMobile;

  constructor(
    private alertService: AlertService,
    private deviceService: DeviceService,
    private router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['alerts']) {
      this.filterAlerts();
    }
  }

  public filterAlerts() {
    this.visibleAlerts = this.alerts.filter(
      (alert) =>
        (!this.filter.showUnread || alert.unread) &&
        (this.filter.selectedTab === 0 ||
          (this.filter.selectedTab === 1 && alert.type === 'companyAlert') ||
          (this.filter.selectedTab === 2 && alert.type === 'listAlert'))
    );
    this.unreadAlerts = this.visibleAlerts.reduce(
      (acc, cur) => (cur.unread ? [...acc, cur.alert_id] : acc),
      [] as string[]
    );
  }

  public markAllAsRead = () => {
    this.updateAlerts(this.visibleAlerts.filter((x) => x.unread)).subscribe();
  };

  public navigateAlert(alert: AlertItem) {
    (alert.unread ? this.updateAlerts([alert]) : EMPTY).subscribe({
      complete: () => {
        this.router.navigateByUrl(
          alert.type === 'companyAlert'
            ? '/companies/' + encodeURIComponent(alert.origin_id) + '/overview'
            : '/my-lists/' + alert.origin_id
        );
        this.alertClicked.emit();
      },
    });
  }

  public alertToggled(alert: AlertItem) {
    this.updateAlerts([alert]).subscribe();
  }

  private updateAlerts(alerts: AlertItem[]) {
    return forkJoin(
      alerts.map((alert) => this.alertService.toggleAlert(alert))
    ).pipe(finalize(() => this.alertService.updateAlerts()));
  }
}
