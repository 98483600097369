// Generated by https://quicktype.io

export interface APIKeyword {
  created_at: string;
  updated_at: null | string;
  id: number;
  importance: number;
  keyword: string;
  subsector_id: number;
  subsectors: {
    created_at: null | string;
    updated_at: null | string;
    id: number;
    name: string;
    parent_id: number;
  };
}
export interface Keyword {
  id: number;
  importance: number;
  keyword: string;
  subsector_id: number;
}

export const toKeyword = ({
  id,
  importance = 0,
  keyword = '',
  subsector_id,
}: APIKeyword): Keyword => ({
  id,
  importance,
  keyword,
  subsector_id,
});
