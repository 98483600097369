import { AuthGuard, Permissions } from '@app/core';
import { ChangePasswordComponent, UnauthenticatedComponent } from '@app/shared';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';

const routes: Route[] = [
  { path: 'login', component: UnauthenticatedComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  {
    path: 'my-lists',
    canActivate: [AuthGuard],
    data: { redirectTo: '/login' },
    loadChildren: () =>
      import('./modules/lists/lists.module').then((m) => m.ListsModule),
  },
  {
    path: 'companies',
    canActivate: [AuthGuard],
    data: { redirectTo: '/login' },
    loadChildren: () =>
      import('./modules/companies/companies.module').then(
        (m) => m.CompaniesModule
      ),
  },
  {
    path: 'backend',
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      redirectTo: '/login',
      permissions: {
        only: [
          Permissions.ManageSectorKeywords,
          Permissions.EditSectorScoringFormularWeights,
        ],
        redirectTo: '/my-lists/home',
      },
    },
    children: [
      {
        path: 'users',
        loadChildren: () =>
          import('./modules/backend/users/users.module').then(
            (m) => m.UsersModule
          ),
      },
      {
        path: 'sectors',
        loadChildren: () =>
          import('./modules/backend/sectors/sectors.module').then(
            (m) => m.SectorsModule
          ),
      },
    ],
  },
  { path: '', pathMatch: 'full', redirectTo: '/my-lists/home' },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { useHash: true, enableTracing: false }),
  ],
  providers: [AuthGuard],
  exports: [RouterModule],
})
export class AppRoutingModule {}
