export type APICountry = string;
export type Country = string;

const topCountries: APICountry[] = [
  'Andorra',
  'Austria',
  'Belgium',
  'Canada',
  'Denmark',
  'Finland',
  'France',
  'Germany',
  'Gibraltar',
  'Ireland',
  'Isle of Man',
  'Italy',
  'Liechtenstein',
  'Luxembourg',
  'Monaco',
  'Netherlands',
  'Norway',
  'Portugal',
  'Scotland',
  'Spain',
  'Sweden',
  'Switzerland',
  'United States',
  'United Kingdom',
];

export const prepareCountries = (input: APICountry[]): Country[] => [
  ...topCountries,
  ...input.filter((country) => !topCountries.includes(country)),
];
