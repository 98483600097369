import { CommonModule, registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AuthInterceptor } from './auth';
import { createTranslateLoader, LANGUAGE_LOADER_CONFIG_TOKEN } from './lang';

import localeUk from '@angular/common/locales/en-GB';
import localeUkExtra from '@angular/common/locales/extra/en-GB';
import { locale } from 'devextreme/localization';
import config from 'devextreme/core/config';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, [new Optional(), LANGUAGE_LOADER_CONFIG_TOKEN]],
      },
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'en-GB' },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    registerLocaleData(localeUk, 'en-GB', localeUkExtra);
    locale('en-GB');
    config({
      defaultUseCurrencyAccountingStyle: false,
    });
    if (parentModule) {
      throw new Error(
        'CoreModule has already been loaded. You should only import Core modules in the AppModule only.'
      );
    }
  }
}
