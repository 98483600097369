import { CompanyService } from '@app/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public readonly lastUpdate$ = this.companyService.lastUpdate$;

  constructor(private companyService: CompanyService) {}

  ngOnInit(): void {}
}
