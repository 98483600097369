import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  DxButtonModule,
  DxChartModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxDrawerModule,
  DxDropDownBoxModule,
  DxDropDownButtonModule,
  DxFilterBuilderModule,
  DxListModule,
  DxLoadIndicatorModule,
  DxLoadPanelModule,
  DxNumberBoxModule,
  DxPopoverModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxRangeSelectorModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTabPanelModule,
  DxTabsModule,
  DxTagBoxModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxToolbarModule,
  DxTooltipModule,
  DxTreeViewModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { NgxPermissionsModule } from 'ngx-permissions';
import { sharedComponents } from './components';

import { RouterModule } from '@angular/router';

const sharedModules = [
  CommonModule,
  TranslateModule,
  NgxPermissionsModule,
  DxPopupModule,
  DxTextAreaModule,
  DxNumberBoxModule,
  DxSelectBoxModule,
  DxButtonModule,
  DxListModule,
  DxToolbarModule,
  DxDrawerModule,
  DxTextBoxModule,
  DxFilterBuilderModule,
  DxDataGridModule,
  DxScrollViewModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxDropDownButtonModule,
  DxDropDownBoxModule,
  DxTabsModule,
  DxTreeViewModule,
  DxChartModule,
  DxTooltipModule,
  DxTagBoxModule,
  DxRangeSelectorModule,
  DxRadioGroupModule,
  DxValidatorModule,
  DxLoadIndicatorModule,
  DxLoadPanelModule,
  DxTabPanelModule,
  DxPopoverModule,
  DxCheckBoxModule,
];

@NgModule({
  declarations: sharedComponents,
  imports: [
    CommonModule,
    TranslateModule,
    NgxPermissionsModule,
    RouterModule,
    DxListModule,
    DxTooltipModule,
    DxToolbarModule,
    DxButtonModule,
    DxDrawerModule,
    DxPopupModule,
    DxTextBoxModule,
    DxFilterBuilderModule,
    DxSelectBoxModule,
    DxScrollViewModule,
    DxDataGridModule,
    DxDropDownButtonModule,
    DxDateBoxModule,
    DxTagBoxModule,
    DxDropDownBoxModule,
    DxTreeViewModule,
    DxValidatorModule,
    DxLoadPanelModule,
    DxNumberBoxModule,
    DxTabPanelModule,
    DxCheckBoxModule,
    DxTabsModule,
  ],
  exports: [...sharedModules, ...sharedComponents],
})
export class SharedModule {}
