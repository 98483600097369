import { Column } from 'devextreme/ui/data_grid';
import { Field } from 'devextreme/ui/filter_builder';
import { DataSet } from '../core/data/interface';
import {
  ApiCompanyListItem,
  ApiCompany,
  APIReference,
  ApiCompanyPublication,
} from './api-result.model';

import { People } from './people.model';

export interface Company extends DataSet {
  attractiveness_score: number | null;
  id: number;
  icon: string | null;
  business_id: string;
  city: string;
  country_code: string | null;
  created_at: string;
  domain: string;
  facebook_url: string | null;
  founded_at: string;
  investors: { business_id: string; name: string }[];
  ebitda: number | null;
  ipos_amount: number | null;
  linkedin_url: string | null;
  ownership_type: string;
  // parent_company_name: string;
  last_employee_count: number | null;
  last_employee_growth: number | null;
  last_investment_date: string | null;
  last_investment_amount: number | null;
  legal_name: string | null;
  long_description: string | null;
  name: string;
  revenue: number | null;
  review_status: string | null;
  review_comment: string | null;
  reviewer: string | null;
  sectors: string | null;
  short_description: string;
  state_code: string | null;
  twitter_url: string | null;
  type: string | null;
  // updated_at: string | null;
  website_url: string | null;
}

export type CompanyReviewScore = {
  comment: string;
  id: number | null;
  criterion_id: number;
  name: string;
  company_id: string | null;
  latest_update_by: string | null;
  latest_update_on: string | null;
  reason_for_exclusion: null | boolean;
  score: number;
};

export type CompanyPublication = {
  business_id: string;
  id: number;
  name: string;
  date: string;
  type: string | null;
  url: string;
  overwrite_reference:
    | {
        [P in keyof Omit<
          CompanyDetail['publications'][number],
          'overwrite_reference'
        >]?: string;
      }
    | null;
};

type CompanyOverwrites = { [Property in keyof CompanyDetail]?: string };

export interface CompanyDetail extends Company {
  parent_company_id: string | null;
  parent_company_name: string | null;
  overwrite_reference: CompanyOverwrites | null;
  assigned_user: {
    name: string;
    id: string;
  } | null;
  aquisitions: {
    business_id: string;
    name: string;
    amount: number | null;
    currency_code: string;
    date: string;
    overwrite_reference:
      | {
          [P in keyof Omit<
            CompanyDetail['aquisitions'][number],
            'overwrite_reference'
          >]?: string;
        }
      | null;
  }[];
  comments: {
    user_id: string;
    name: string;
    date: string;
    comment: string;
  }[];
  references: Reference[];
  references_count?: number;
  revenues: {
    cagr: number | null;
    revenue_per_employee: number | null;
    revenue_growth_rate: number | null;
    business_id: string;
    date: string | null;
    revenue: number | null;
    ebitda: number | null;
    currency_code: string;
    overwrite_reference:
      | {
          [P in keyof Omit<
            CompanyDetail['revenues'][number],
            'overwrite_reference'
          >]?: string;
        }
      | null;
  }[];
  review_statuses: {
    name: string | null;
    date: string;
    comment: string | null;
    status: string;
  }[];
  review_scores: CompanyReviewScore[];
  employees: {
    business_id: string;
    date: string;
    count: number;
    employee_range: string | null;
    growth_rate: number;
    overwrite_reference:
      | {
          [P in keyof Omit<
            CompanyDetail['employees'][number],
            'overwrite_reference'
          >]?: string;
        }
      | null;
  }[];
  investments: {
    id: number;
    business_id: string;
    name: string | null;
    date: string;
    amount: number | null;
    currency_code: string | null;
    post_money_evaluation: number | null;
    type: string | null;
    overwrite_reference:
      | {
          [P in keyof Omit<
            CompanyDetail['investments'][number],
            'overwrite_reference'
          >]?: string;
        }
      | null;
    facilitators: { name: string; business_id: string }[];
    investors: {
      business_id: string;
      name: string;
      amount: number | null;
      currency: string;
      is_lead_investor: boolean;
    }[];
  }[];
  ipo: {
    date: string;
    business_id: string;
    ipos_amount: number | null;
    valuation_amount: number | null;
    valuation_currency_code: string | null;
    raised_amount: number | null;
    raised_currency_code: string | null;
    share_amount: number | null;
    share_currency_code: string | null;
    stock_exchange_symbol: string | null;
    stock_symbol: string | null;
    overwrite_reference:
      | {
          [P in keyof Omit<
            CompanyDetail['ipo'][number],
            'overwrite_reference'
          >]?: string;
        }
      | null;
  }[];
  people: People[];
  publications: CompanyPublication[];
  publications_count?: number;
  sector: {
    subsector_id: number;
    business_id: string | null;
    name: string;
    overwrite_reference: null | { subsector_id: string };
  }[];
  similar_companies: {
    business_id: string;
    name: string;
    attractiveness_score: number | null;
    sector: string | null;
  }[];
  valuation: {
    business_id: string;
    date: string;
    valuation: number;
    currency_code: string | null;
    overwrite_reference:
      | {
          [P in keyof Omit<
            CompanyDetail['valuation'][number],
            'overwrite_reference'
          >]?: string;
        }
      | null;
  }[];
}

export const toPublication = (
  x: ApiCompanyPublication
): CompanyPublication => ({
  business_id: x.business_id,
  id: x.id,
  name: x.name || x.url,
  date: x.date,
  type: x.type,
  url: x.url,
  overwrite_reference: x.overwrite_reference,
});

export const toCompanyListItem = (input: ApiCompanyListItem): Company => ({
  attractiveness_score: input.companies_latest
    ? input.companies_latest.attractiveness
    : input.attractivenesses instanceof Array &&
      input.attractivenesses[0] &&
      typeof input.attractivenesses[0].score === 'number'
    ? input.attractivenesses[0].score
    : null,
  id: input.id,
  icon: input.icon,
  business_id: encodeURIComponent(input.business_id),
  city: input.city,
  country_code: input.country_code,
  created_at: input.created_at,
  domain: input.domain,
  facebook_url: input.facebook_url,
  founded_at: input.founded_at,
  ebitda: input.companies_latest ? input.companies_latest.ebitda : null,
  ipos_amount: input.companies_latest
    ? input.companies_latest.ipo_valuation_amount
    : input.ipos instanceof Array &&
      input.ipos[0] &&
      typeof input.ipos[0].valuation_amount === 'number'
    ? input.ipos[0].valuation_amount
    : null,
  investors: (input.companies_latest
    ? input.companies_latest.investor_names_with_business_ids || []
    : []
  ).sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)),
  linkedin_url: input.linkedin_url,
  ownership_type: input.ownership_type,
  last_employee_count: input.companies_latest
    ? input.companies_latest.employee_count
    : input.employee_details instanceof Array &&
      input.employee_details[0] &&
      typeof input.employee_details[0].count === 'number'
    ? input.employee_details[0].count
    : null,
  last_employee_growth: input.companies_latest
    ? input.companies_latest.employee_growth_rate
    : input.employee_details instanceof Array &&
      input.employee_details[0] &&
      typeof input.employee_details[0].growth_rate === 'number'
    ? input.employee_details[0].growth_rate
    : null,
  last_investment_date: input.companies_latest
    ? input.companies_latest.investment_date
    : input.investments instanceof Array &&
      input.investments[0] &&
      typeof input.investments[0].date === 'string'
    ? input.investments[0].date
    : null,
  last_investment_amount: input.companies_latest
    ? input.companies_latest.investment_amount
    : input.investments instanceof Array &&
      input.investments[0] &&
      typeof input.investments[0].amount === 'number'
    ? input.investments[0].amount
    : null,
  legal_name: input.legal_name,
  long_description: input.long_description,
  name: input.name,
  revenue: input.companies_latest
    ? input.companies_latest.revenue
    : input.financial_statements instanceof Array &&
      input.financial_statements[0] &&
      typeof input.financial_statements[0].revenue === 'number'
    ? input.financial_statements[0].revenue
    : null,
  review_status: input.companies_latest
    ? input.companies_latest.review_status
    : input.review_statuses instanceof Array &&
      input.review_statuses[0] &&
      typeof input.review_statuses[0].status === 'string'
    ? input.review_statuses[0].status
    : null,
  review_comment: input.companies_latest
    ? input.companies_latest.review_comment
    : input.review_statuses instanceof Array &&
      input.review_statuses[0] &&
      typeof input.review_statuses[0].comment === 'string'
    ? input.review_statuses[0].comment
    : null,
  reviewer: input.companies_latest
    ? input.companies_latest.review_reviewer_name
    : input.assignees_companies?.users
    ? input.assignees_companies?.users.first_name +
      ' ' +
      input.assignees_companies?.users.last_name
    : null,
  sectors: input.companies_latest
    ? input.companies_latest.subsector_name
    : null,
  short_description: input.short_description,
  state_code: input.state_code,
  twitter_url: input.twitter_url,
  website_url: input.website_url,
  type: input.type,
});

export const toCompanyDetail = (input: ApiCompany): CompanyDetail => ({
  ...toCompanyListItem(input),
  assigned_user: input.assignees_companies
    ? {
        name: `${input.assignees_companies.users.first_name} ${input.assignees_companies.users.last_name} `,
        id: input.assignees_companies.user_id,
      }
    : null,
  parent_company_id: input.parent_id,
  parent_company_name:
    (input.parent_company && input.parent_company.legal_name) || null,
  overwrite_reference: input.overwrite_reference || null,
  aquisitions: input.acquisitions_acquisitions_acquirer_idTocompanies?.map(
    (x) =>
      ({
        business_id:
          x.companies_acquisitions_acquiree_idTocompanies.business_id,
        name: x.companies_acquisitions_acquiree_idTocompanies.name,
        amount: x.amount,
        currency_code: x.currency_code || 'USD',
        date: x.date,
        overwrite_reference: x.overwrite_reference,
      } || [])
  ),
  comments: (
    input.comments?.map((x) => ({
      user_id: x.author_id,
      name: x.users.first_name + ' ' + x.users.last_name,
      comment: x.text,
      date: x.created_at,
    })) || []
  ).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()),
  references: [],
  references_count: 0,
  revenues:
    input.financial_statements?.map((x) => ({
      cagr: x.cagr,
      revenue_per_employee: x.revenue_per_employee,
      revenue_growth_rate: x.revenue_growth_rate,
      business_id: x.business_id,
      date: x.date,
      revenue: x.revenue,
      ebitda: x.ebitda,
      currency_code: x.currency_code,
      overwrite_reference: x.overwrite_reference,
    })) || [],
  employees:
    input.employee_details?.map((x) => ({
      business_id: x.business_id,
      date: x.date,
      count: x.count,
      employee_range: x.employee_range,
      growth_rate: x.growth_rate,
      overwrite_reference: x.overwrite_reference,
    })) || [],
  investments:
    input.investments?.map((x) => ({
      business_id: x.business_id,
      id: x.id,
      name: x.name,
      date: x.date,
      amount: x.amount,
      currency_code: x.currency_code,
      post_money_evaluation: x.post_money_evaluation,
      type: x.type,
      overwrite_reference: x.overwrite_reference,
      facilitators: x.facilitators.map((y) => ({
        name: y.companies.name,
        business_id: y.companies.business_id,
      })),
      investors: x.investors.map((y) => ({
        business_id: y.companies.business_id,
        name: y.companies.name,
        amount: y.amount,
        currency: y.currency || 'USD',
        is_lead_investor: y.is_lead_investor,
      })),
    })) || [],
  ipo:
    input.ipos?.map((x) => ({
      business_id: x.business_id,
      date: x.date,
      ipos_amount: -1,
      valuation_amount: x.valuation_amount,
      valuation_currency_code: x.valuation_currency_code,
      raised_amount: x.raised_amount,
      raised_currency_code: x.raised_currency_code,
      share_amount: x.share_amount,
      share_currency_code: x.share_currency_code,
      stock_exchange_symbol: x.stock_exchange_symbol,
      stock_symbol: x.stock_symbol,
      overwrite_reference: x.overwrite_reference,
    })) || [],
  people:
    input.people?.map((x) => ({
      ...x,
      overwrite_reference: x.overwrite_reference || null,
    })) || [],
  publications: input.publications?.map(toPublication) || [],
  publications_count: 0,
  sector:
    input.companies_subsectors
      ?.filter((x) => x.subsector_id !== null)
      .map((x) => ({
        subsector_id: x.subsectors.id,
        business_id: x.business_id,
        name: x.subsectors.name,
        overwrite_reference: x.overwrite_reference,
      })) || [],
  valuation:
    input.valuations?.map((x) => ({
      business_id: x.business_id,
      date: x.date,
      valuation: x.valuation,
      currency_code: x.currency_code,
      overwrite_reference: x.overwrite_reference,
    })) || [],
  similar_companies:
    input.similar_companies?.map((x) => ({
      business_id: x.business_id || '',
      name: x.legal_name || '',
      attractiveness_score:
        input.attractivenesses instanceof Array &&
        input.attractivenesses[0] &&
        typeof input.attractivenesses[0].score === 'number'
          ? input.attractivenesses[0].score
          : null,
      sector: (
        x.companies_subsectors
          ?.filter((x) => x.subsector_id !== null)
          .map((x) => x.subsectors.name) || []
      ).join(', '),
    })) || [],
  review_statuses:
    input.review_statuses?.map((x) => ({
      date: x.created_at,
      comment: x.comment || '',
      name: (x.users?.first_name || '') + ' ' + (x.users?.last_name || ''),
      status: x.status,
    })) || [],
  review_scores: [
    { name: 'company.score.entryBarriers', criterion_id: 0 },
    { name: 'company.score.missionCriticality', criterion_id: 1 },
    { name: 'company.score.niche', criterion_id: 2 },
    { name: 'company.score.differentiationAndAdvantage', criterion_id: 3 },
    { name: 'company.score.techEnabled', criterion_id: 4 },
    { name: 'company.score.businessCharacteristics', criterion_id: 5 },
    //{ name: 'company.score.revenueSecurity', criterion_id: 6 },
    //{ name: 'company.score.substitutionRisk', criterion_id: 7 },
    //{ name: 'company.score.fcf', criterion_id: 8 },
    //{ name: 'company.score.underlyingMarketGrowth', criterion_id: 9 },
    //{ name: 'company.score.valuePotential', criterion_id: 10 },
  ].reduce((acc, { name, criterion_id }) => {
    const score = input.review_scores?.find(
      (x) =>
        x.criterion_id === criterion_id ||
        (x.criterion_id === null && criterion_id === 0)
    );
    return [
      ...acc,
      !score
        ? {
            comment: '',
            id: null,
            criterion_id,
            name,
            company_id: null,
            latest_update_by: null,
            latest_update_on: null,
            reason_for_exclusion: null,
            score: 0,
          }
        : {
            ...score,
            latest_update_by:
              score.users && score.users.first_name && score.users.last_name
                ? score.users.first_name + ' ' + score.users.last_name
                : null,
            comment: score.comment || '',
            score: score.score || 0,
            name,
          },
    ];
  }, [] as CompanyReviewScore[]),
});

export type Reference = {
  business_id: string;
  date: string;
  mentioned_company_id: string | null;
  referencing_source_id: string | null;
  name: string;
};

export const toReference = (input: APIReference): Reference => ({
  business_id: input.business_id,
  date: input.date,
  mentioned_company_id: input.mentioned_company_id,
  referencing_source_id: input.referencing_source_id,
  name: input.sources?.name || '',
});

export interface CompanyShortInfo extends DataSet {
  id: number;
  business_id: string;
  name: string;
  city: string;
  domain: string;
  legal_name: string | null;
  companies_subsectors: {
    id: number;
    subsector_id: number;
    company_id: string;
    subsectors: {
      id: number;
      name: string;
      sector_id: number;
    };
  }[];
}

export interface ScoreCriteria {
  name: string;
  value: number;
  excluded: boolean;
  comment: string;
  last_updated?: {
    first_name: string;
    last_name: string;
    date: string;
  };
}

// parent_id;

export const companyColumnMap = {
  sectors: 'five_arrows_classification',
  reviewer: 'review_reviewer_name',
  review_status: 'five_arrows_feedback',
  last_employee_growth: 'employee_growth',
  last_investment_amount: 'last_funded_amount',
};

export const companyColumnUnMap = Object.entries(companyColumnMap).reduce(
  (acc, [key, val]) => ({ ...acc, [val]: key }),
  {} as { [key: string]: string }
);

export const companyRelationsMap = {
  attractiveness_score: 'attractiveness',
  last_employee_count: 'employee_count',
  last_employee_growth: 'employee_growth_rate',
  last_investment_amount: 'investment_amount',
  last_investment_date: 'investment_date',
  revenue: 'revenue',
  review_comment: 'review_comment',
  review_status: 'review_status',
  reviewer: 'review_reviewer_name',
  ebitda: 'ebitda',
  ipos_amount: 'ipo_valuation_amount',
  sectors: 'subsector_name',
  investors: 'investor_names',
} as const;

export const companyColumns: Column[] = [
  {
    dataField: 'id',
    dataType: 'number',
    visible: false,
    showInColumnChooser: false,
  },
  {
    dataField: 'business_id',
    dataType: 'string',
    visible: false,
    showInColumnChooser: false,
  },
  {
    dataField: 'name',
    dataType: 'string',
    caption: 'Company',
  },
  {
    dataField: 'short_description',
    dataType: 'string',
    caption: 'Description',
    cellTemplate: 'cellDescriptionTemplate',
    width: 300,
  },
  {
    dataField: 'keywords',
    dataType: 'string',
    caption: 'pseudo-filter-column',
    visible: false,
    showInColumnChooser: false,
  },
  {
    dataField: 'founded_at',
    dataType: 'date',
    format: 'year',
    caption: 'Founded',
  },
  { dataField: 'country_code', dataType: 'string', caption: 'Country' },
  {
    dataField: 'last_employee_count',
    dataType: 'number',
    caption: 'Employees',
  },
  {
    dataField: 'sectors',
    dataType: 'string',
    caption: 'Subsector',
  },
  {
    dataField: 'review_status',
    dataType: 'string',
    caption: 'Status',
  },
  {
    dataField: 'review_comment',
    dataType: 'string',
    caption: 'Comment',
    cellTemplate: 'cellDescriptionTemplate',
    width: 300,
  },
  {
    dataField: 'attractiveness_score',
    caption: 'Score',
    dataType: 'number',
    format: '###.000',
  },
  {
    dataField: 'investors',
    dataType: 'string',
    cellTemplate: 'cellInvestorsTemplate',
  },
  {
    dataField: 'last_investment_amount',
    dataType: 'number',
    caption: 'Latest Funded Amount',
    format: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'narrowSymbol',
    },
  },
  {
    dataField: 'last_investment_date',
    caption: 'Latest Funded Date',
    dataType: 'date',
  },
  {
    dataField: 'last_funded_year',
    dataType: 'date',
    caption: 'pseudo-filter-column',
    visible: false,
    showInColumnChooser: false,
  },
  {
    dataField: 'last_employee_growth',
    caption: 'Employee Growth',
    dataType: 'number',
    format: ",##0'%'",
  },
  {
    dataField: 'reviewer',
    dataType: 'string',
    caption: 'Reviewer',
    // allowSorting: false,
    // allowFiltering: false,
  },
  {
    dataField: 'revenue',
    dataType: 'number',
    format: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'narrowSymbol',
    },
    visible: false,
  },
  {
    dataField: 'city',
    dataType: 'string',
    visible: false,
  },
  {
    dataField: 'domain',
    dataType: 'string',
    visible: false,
  },
  {
    dataField: 'ebitda',
    caption: 'EBITDA amount',
    dataType: 'number',
    format: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'narrowSymbol',
    },
    visible: false,
  },
  {
    dataField: 'last_ebitda',
    dataType: 'number',
    caption: 'pseudo-filter-column',
    visible: false,
    showInColumnChooser: false,
  },
  {
    dataField: 'ipos_amount',
    caption: 'IPO amount',
    dataType: 'number',
    format: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'narrowSymbol',
    },
    visible: false,
  },
  {
    dataField: 'legal_name',
    dataType: 'string',
    visible: false,
  },
  {
    dataField: 'long_description',
    dataType: 'string',
    cellTemplate: 'cellDescriptionTemplate',
    width: 300,
    visible: false,
  },
  // {
  //   dataField: 'parent_company_name',
  //   visible: false,
  // },
  {
    dataField: 'ownership_type',
    dataType: 'string',
    visible: false,
  },
  {
    dataField: 'state_code',
    dataType: 'string',
    visible: false,
  },
  {
    dataField: 'type',
    dataType: 'string',
    visible: false,
  },
  {
    dataField: 'twitter_url',
    dataType: 'string',
    visible: false,
    showInColumnChooser: false,
  },
  {
    dataField: 'facebook_url',
    dataType: 'string',
    visible: false,
    showInColumnChooser: false,
  },
  {
    dataField: 'linkedin_url',
    dataType: 'string',
    visible: false,
    showInColumnChooser: false,
  },
  {
    dataField: 'website_url',
    dataType: 'string',
    visible: false,
    showInColumnChooser: false,
  },
  {
    cellTemplate: 'cellIconTemplate',
    dataType: 'string',
    allowFiltering: false,
    allowSorting: false,
  },
];

export const companyFields = companyColumns
  .filter(
    (item) =>
      (item.dataField || item.name) &&
      (item.allowFiltering !== false || item.allowHeaderFiltering !== false) &&
      item.showInColumnChooser !== false
  )
  .map(
    (column) =>
      ({
        ...column,
        dataField: column.dataField || column.name,
      } as Field)
  );

export type CompanyFilterValues = {
  name: string;
  review_status: string;
  reviewer: string;
  sectors: number[];
  last_employee_countFrom: number | null;
  last_employee_countTo: number | null;
  investors: string;
  revenueFrom: number | null;
  revenueTo: number | null;
  ownership_type: string;
  country_code: string[];
  founded_atFrom: string | number | Date;
  founded_atTo: string | number | Date;
  last_investment_amountFrom: number | null;
  last_investment_amountTo: number | null;
  city: string;
  attractiveness_scoreFrom: number | null;
  attractiveness_scoreTo: number | null;
  last_employee_growthFrom: number | null;
  last_employee_growthTo: number | null;
  last_funded_yearFrom: string | number | Date;
  last_funded_yearTo: string | number | Date;
  last_ebitdaFrom: number | null;
  last_ebitdaTo: number | null;
  keywords: string[];
};
