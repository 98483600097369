import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spryfox-fapi-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
  ngOnInit(): void {}
}
