import { ApiCompanies } from './api-result.model';
export interface APIAlerts {
  companyAlerts: APICompanyAlert[];
  listAlerts: APIListAlert[];
}

interface APICompanyAlert {
  id: string;
  origin_id: string;
  reason: string;
  read: boolean;
  created_at: string;
  updated_at: string;
  user_id: string;
  previous_value: number;
  actual_value: number;
  companies: ApiCompanies;
  alert_reasons: {
    id: string;
    reason: string;
    reason_string: string;
    reason_description: string;
  };
  users: {
    created_at: string;
    updated_at: string;
    id: string;
    email: string;
    first_name: string;
    last_name: string;
  };
}
interface APIListAlert {
  id: string;
  origin_id: number;
  reason: string;
  read: boolean;
  created_at: string;
  updated_at: string;
  user_id: string;
  previous_value: number;
  actual_value: number;
  lists: {
    created_at: string;
    updated_at: null;
    id: number;
    description: string;
    name: string;
    owner_id: string;
    query: string;
    type: string;
  };
  alert_reasons: {
    id: string;
    reason: string;
    reason_string: string;
    reason_description: string;
  };
  users: {
    created_at: string;
    updated_at: string;
    id: string;
    email: string;
    first_name: string;
    last_name: string;
  };
}

export interface AlertItem {
  alert_id: string;
  origin_id: string | number;
  type: 'companyAlert' | 'listAlert';
  name: string;
  alert_date: Date;
  reason: string;
  unread: boolean;
  description: string;
}

const isListAlert = (
  input: APIListAlert | APICompanyAlert
): input is APIListAlert => !!(input as APIListAlert)['lists'];

export const toAlert = (input: APIListAlert | APICompanyAlert): AlertItem => ({
  alert_id: input.id,
  origin_id: input.origin_id,
  type: isListAlert(input) ? 'listAlert' : 'companyAlert',
  name: isListAlert(input) ? input.lists.name : input.companies.name,
  alert_date: new Date(input.created_at),
  reason: input.alert_reasons.reason_string, //`alert.reason.${input.alert_reasons.reason}`,
  description: input.alert_reasons.reason_description,
  unread: !input.read,
});
