import { HttpClient } from '@angular/common/http';
import { Reference, toReference } from './company.model';
import { environment } from '@app/environment';
import { DataSourceAdapter } from '../core/data';
import { APIReference } from './api-result.model';

export const getReferencesSource = (http: HttpClient, companyId: string) =>
  new DataSourceAdapter<Reference>(
    {
      selectAll: (path, options) => {
        if (
          (!options ||
            typeof options.skip !== 'number' ||
            typeof options.take !== 'number') &&
          !environment.production
        ) {
          console.warn('pagination options missing', { path, options });
          // return throwError(() => new Error('Malformed Request'));
        }
        return http.post<{ totalCount: number; data: APIReference[] }>(path, {
          method: 'fetchReference',
          data: {
            companyId,
            page:
              (options &&
                options.skip &&
                options.take &&
                options.skip / options.take) ||
              0,
            resultsPerPage: (options && options.take) || 10,
          },
        });
      },
    },
    {
      path: `${environment.apiBase}/fetchReference`,
      key: 'business_id',
      postProcess: (data: APIReference[]) => data.map(toReference),
      pageSize: 10,
    }
  ).asDataSource();
