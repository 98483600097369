export const scoringWeights = [
  { name: 'company.scoringWeight.employeeGrowth', criterion_id: 1 },
  { name: 'company.scoringWeight.presenceOnList', criterion_id: 2 },
  { name: 'company.scoringWeight.databaseRanking', criterion_id: 3 },
  { name: 'company.scoringWeight.sourceTopLists', criterion_id: 4 },
  { name: 'company.scoringWeight.sourceBuyersGuide', criterion_id: 5 },
  { name: 'company.scoringWeight.sourceConferences', criterion_id: 6 },
  { name: 'company.scoringWeight.awardsLists', criterion_id: 7 },
  { name: 'company.scoringWeight.aquisitionsMade', criterion_id: 8 },
  { name: 'company.scoringWeight.revenueGrowth', criterion_id: 9 },
  { name: 'company.scoringWeight.ebitdaMargin', criterion_id: 10 },
  // { name: 'company.scoringWeight.subsectorGrouping', criterion_id: 11 },
  // { name: 'company.scoringWeight.currentOwnership', criterion_id: 12 },
];

export type APIScoringWeight = {
  created_at: string;
  criterion_id: number;
  id: number;
  subsector_id: number;
  subsectors: {
    created_at: string | null;
    updated_at: string | null;
    id: number;
    name: string;
    parent_id: number;
  };
  updated_at: string | null;
  weight: number;
};

export type CompanyScoringWeight = {
  id: number | null;
  criterion_id: number;
  subsector_id: number;
  name: string;
  weight: number;
};

export const toScoringWeights = (
  subsector_id: number,
  input: APIScoringWeight[]
) =>
  scoringWeights.reduce((acc, { name, criterion_id }) => {
    const weight = input.find((x) => x.criterion_id === criterion_id);
    return [
      ...acc,
      !weight
        ? {
            id: null,
            criterion_id,
            subsector_id,
            name,
            weight: 0,
          }
        : {
            ...weight,
            score: weight.weight || 0,
            name,
          },
    ];
  }, [] as CompanyScoringWeight[]);
