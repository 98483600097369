/* eslint-disable @typescript-eslint/no-explicit-any */
type FilterValue =
  | number
  | string
  | boolean
  | Date
  | string[]
  | number[]
  | null;
type Condition = [string, string, FilterValue | [FilterValue, FilterValue]];
type NegationGroup = ['!', Condition | any[]];

const isGroup = (criteria: any[]) => {
  if (!Array.isArray(criteria)) {
    return false;
  }
  return (
    criteria.length < 2 ||
    Array.isArray(criteria[0]) ||
    Array.isArray(criteria[1])
  );
};

const isNegationGroup = (group: any[]): group is NegationGroup => {
  return group && group.length > 1 && '!' === group[0] && !isCondition(group);
};

export const isCondition = (criteria: any): criteria is Condition => {
  if (!Array.isArray(criteria)) {
    return false;
  }
  return (
    criteria.length > 1 &&
    !Array.isArray(criteria[0]) &&
    !Array.isArray(criteria[1])
  );
};
const capitalize = (input: string) =>
  input.charAt(0).toUpperCase() + input.slice(1);

const sanitizeField = (field: string) =>
  `'${capitalize(field.replace('_', ' '))}'`;

const getConditionText = ([field, operation, value]: Condition) => {
  return `${sanitizeField(field)} ${operation} ${
    typeof value === 'string' || value === null
      ? `"${value}"`
      : value.toString()
  }`;
};

export const getFilterText = (filter: any[], innerGroup = false): string => {
  if (isCondition(filter)) {
    return getConditionText(filter);
  } else if (isNegationGroup(filter)) {
    return 'not ' + getFilterText(filter[1]);
  } else if (isGroup(filter)) {
    return (
      filter.reduce(
        (acc, item) => {
          if (typeof item === 'string') {
            return `${acc} ${item}`;
          } else {
            return `${acc} ${getFilterText(item, true)}`;
          }
        },
        innerGroup ? '(' : ''
      ) + (innerGroup ? ' )' : '')
    );
  } else {
    return '';
  }
};

export const ensureConditionArray = (input: unknown[]): Condition[] => {
  if (isCondition(input)) {
    return [input];
  }
  return input.filter((x): x is Condition => isCondition(x));
};

export const mapFilterColumns = (
  filter: any[],
  columnMap: { [key: string]: string }
): any[] => {
  if (isCondition(filter)) {
    const [column, operator, value] = filter;
    return [columnMap[column] || column, operator, value];
  } else if (isNegationGroup(filter)) {
    const [operator, next] = filter;
    return [operator, mapFilterColumns(next, columnMap)];
  } else if (isGroup(filter)) {
    return filter.reduce((acc, cur) => {
      if (typeof cur === 'string') {
        return [...acc, cur];
      } else {
        return [...acc, mapFilterColumns(cur, columnMap)];
      }
    }, [] as unknown[]);
  }
  return filter;
};
