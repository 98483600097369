import { DataSet } from '../core/data/interface';

export interface ApiUser {
  created_at: string;
  email: string | null;
  first_name: string;
  id: string;
  last_name: string;
  updated_at: string | null;
}
export interface User extends DataSet {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
}
