import { HttpClient } from '@angular/common/http';
import { LoadOptions } from 'devextreme/data';
import { of, tap } from 'rxjs';
import { DataSourceAdapter } from './data-source.adapter';
import { DataApiAdapter, DataSet, DataSourceConfig } from './interface';

export class AutocompleteApiAdapter<ReadType extends DataSet = any>
  implements DataApiAdapter<ReadType>
{
  private cache: ReadType[] = [];
  constructor(private http: HttpClient, private requestType: string) {}

  selectAll(path: string, options?: LoadOptions<ReadType>) {
    return options?.searchValue
      ? this.http
          // .get<ReadType[]>(
          //   `${path}?requestType=${this.requestType}&needle=${options?.searchValue}`
          // )
          .post<ReadType[]>(path, {
            method: 'autocomplete',
            data: {
              requestType: this.requestType,
              needle: options?.searchValue,
            },
          })
          .pipe(tap((result) => (this.cache = result)))
      : of(this.cache);
  }
}

export class AutocompleteDataSource<
  T extends DataSet
> extends DataSourceAdapter<T> {
  constructor(http: HttpClient, requestType: string, config: DataSourceConfig) {
    super(new AutocompleteApiAdapter<T>(http, requestType), config);
  }
}
