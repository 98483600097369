<footer class="w3-bar w3-theme-tertiary-l5">
  <!--<div class="w3-bar-item w3-panel w3-margin-left" translate>
    common.label.imprint
  </div>
  <div class="w3-bar-item w3-panel" translate>common.label.privacy</div>-->
  <div
    class="w3-bar-item w3-panel w3-mobile"
    *ngIf="lastUpdate$ | async as lastUpdated"
    translate
    [translateParams]="{ value: lastUpdated | date }"
  >
    common.label.lastDataUpdate
  </div>
  <div class="w3-bar-item w3-panel w3-right w3-margin-right w3-mobile">
    2023 Rothschild & Co ©
  </div>
</footer>
