import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ListItem, MenuList } from '@app/model';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent {
  @Input() menu: MenuList[] = [];
  @Output() itemSelected = new EventEmitter<{
    list: number | string;
    item: ListItem;
  }>();
  @Output() actionSelected = new EventEmitter<number>();

  public editorActive = false;

  constructor(private router: Router) {}

  listItemClicked = (list: number | string, item: ListItem) => {
    this.itemSelected.emit({ list, item });

    this.router.navigateByUrl(
      list === 1
        ? `/my-lists/${item.id}`
        : `/companies/${encodeURIComponent(item.id)}/overview`
    );
  };

  listActionClicked = (list: number) => {
    this.actionSelected.emit(list);
  };
}
