import { ApiUser } from './user.model';
export interface ApiOverwrite {
  entity: string;
  unique_record_id: string;
  mod_attribute: string;
  previous_value: unknown;
  overwrite_value: unknown;
  overwrite_by: string;
  import_value: unknown;
  created_at: string;
  updated_at: string | null;
  id: string;
  users: ApiUser;
}

export interface OverwriteConfig {
  label?: string;
  mod_attribute: string;
  datatype: 'integer' | 'decimal' | 'date' | 'string' | 'boolean' | 'relation';
  values?: unknown[];
}

export interface OverwriteDataset extends OverwriteConfig {
  import_value?: any;
  overwrite_value?: any;
  overwrite_by?: string;
  created_at?: string;
  updated_at?: string | null;
}

const sanitizeLabel = (input: string) => {
  const sanitized = input.replace('_', ' ').trim();
  return !sanitized.length
    ? input
    : `${sanitized.substring(0, 1).toUpperCase()}${sanitized.substring(1)}`;
};

export const toOverwriteDataset = <T = unknown>(
  config: OverwriteConfig[],
  overwrites: ApiOverwrite[],
  dataset: T
) =>
  config.map(({ mod_attribute, datatype, label, values }): OverwriteDataset => {
    const overwrite = overwrites.find((x) => x.mod_attribute === mod_attribute);
    return {
      ...(values instanceof Array ? { values } : {}),
      ...(overwrite
        ? {
            ...overwrite,
            overwrite_value: dataset[mod_attribute as keyof T],
            datatype,
            label: label || sanitizeLabel(mod_attribute),
            overwrite_by: overwrite.users
              ? `${overwrite.users?.first_name} ${overwrite.users?.last_name}`
              : overwrite.overwrite_by,
          }
        : {
            mod_attribute,
            datatype,
            label: label || sanitizeLabel(mod_attribute),
            import_value: dataset[mod_attribute as keyof T],
          }),
    };
  });
