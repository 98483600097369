<header class="w3-theme-primary">
  <dx-toolbar class="header-toolbar w3-theme-primary w3-padding-32">
    <dxi-item
      location="before"
      widget="dxButton"
      cssClass="menu-button"
      [options]="{
        icon: 'fa-regular fa-bars w3-text-white',
        stylingMode: 'text',
        onClick: toggle
      }"
    >
    </dxi-item>
    <dxi-item location="center" cssClass="header-title">
      <div *dxTemplate>
        <a routerLink="/my-lists/home">
          <img
            src="assets/img/Five_Arrows_Logo_white.svg"
            class="w3-hide-small w3-hide-medium"
            height="41px"
          />
          <img
            src="assets/img/logo_small_white.svg"
            class="w3-hide-large"
            height="41px"
          />
        </a>
      </div>
    </dxi-item>
    <dxi-item location="after" cssClass="w3-hide-small">
      <div *dxTemplate>
        <ng-container
          *ngIf="!showMobileSearch"
          [ngTemplateOutlet]="companySearch"
          [ngTemplateOutletContext]="{ width: 300 }"
        ></ng-container>
      </div>
    </dxi-item>
    <dxi-item location="after" cssClass="w3-hide-medium w3-hide-large">
      <div *dxTemplate>
        <dx-button
          class="w3-margin-right search-toggle"
          stylingMode="text"
          icon="fa-regular fa-magnifying-glass fa-lg w3-text-white"
          (onClick)="showMobileSearch = !showMobileSearch"
        ></dx-button>
      </div>
    </dxi-item>
    <dxi-item location="after">
      <div *dxTemplate>
        <span
          class="fa-layers fa-fw alert-toggle"
          [ngClass]="{ 'w3-margin-right': !unreadAlerts }"
          style="cursor: pointer"
          (click)="showAlerts.emit()"
        >
          <i
            [ngClass]="
              unreadAlerts ? 'fa-solid fa-bell-on' : 'fa-regular fa-bell'
            "
          ></i>
          <span class="fa-layers-counter" *ngIf="unreadAlerts">{{
            unreadAlerts
          }}</span>
        </span>
      </div>
    </dxi-item>
    <dxi-item
      location="after"
      [text]="userName$ | async"
      cssClass="w3-text-white w3-hide-small"
    ></dxi-item>
    <dxi-item
      location="after"
      cssClass="user-options w3-text-white no-transform"
    >
      <div *dxTemplate>
        <dx-drop-down-button
          stylingMode="text"
          icon="fa-regular fa-gear w3-text-white"
          position="bottom"
          text=""
          keyExpr="id"
          displayExpr="name"
          [items]="options"
          itemTemplate="option"
          [dropDownOptions]="{
            width: 230,
            wrapperAttr: { class: 'options-menu-dropdown' }
          }"
          (onItemClick)="optionClicked($event)"
        >
          <div
            *dxTemplate="let item of 'option'"
            [ngClass]="item.isExternal ? 'external-link' : ''"
          >
            <ng-container *ngIf="!item.isLink">{{ item.name }}</ng-container>
            <a
              *ngIf="item.isLink && !item.isExternal"
              class="no-transform"
              [routerLink]="item.id"
              >{{ item.name }}</a
            >
            <a
              *ngIf="item.isLink && item.isExternal"
              class="no-transform"
              target="_blank"
              [href]="item.id"
              >{{ item.name }}</a
            >
          </div>
        </dx-drop-down-button>
      </div>
    </dxi-item>
  </dx-toolbar>
  <div
    class="w3-padding w3-hide-medium w3-hide-large"
    style="height: 70px"
    *ngIf="showMobileSearch"
  >
    <ng-container
      [ngTemplateOutlet]="companySearch"
      [ngTemplateOutletContext]="{ width: 'auto' }"
    ></ng-container>
  </div>
</header>

<ng-template #companySearch let-width="width">
  <dx-select-box
    [width]="width"
    class="w3-theme-primary w3-text-white w3-bottombar w3-margin-right"
    [dataSource]="companies"
    displayExpr="name"
    valueExpr="business_id"
    placeholder="Search companies"
    [searchEnabled]="true"
    [searchTimeout]="500"
    [minSearchLength]="3"
    [openOnFieldClick]="false"
    (onValueChanged)="goToCompany($event.value)"
  >
    <dxi-button
      name="btn0"
      location="after"
      [options]="{
        width: 34,
        height: 34,
        template: 'btn0Template',
        stylingMode: 'text',
        cssClass: 'no-pointer'
      }"
    >
      <div
        *dxTemplate="let data of 'btn0Template'"
        class="w3-theme-primary"
        id="autocomplete-loading-indicator"
      >
        <dx-load-panel
          [wrapperAttr]="{ class: 'autocomplete-loading-indicator' }"
          container="#autocomplete-loading-indicator"
          message=""
          [position]="{ of: '#autocomplete-loading-indicator' }"
          [visible]="autocompleteLoading"
          [showIndicator]="true"
          [showPane]="false"
          [shading]="false"
          [hideOnOutsideClick]="false"
        >
        </dx-load-panel>
      </div>
    </dxi-button>
    <div *dxTemplate="let item of 'item'">
      <div class="company-name">
        <b>{{ item.name }}</b>
      </div>
      <div class="company-city">
        {{ item.city }}
      </div>
      <div class="company-domain">{{ item.domain }}</div>
      <div class="company-sectors">{{ getSectorList(item) }}</div>
    </div>
  </dx-select-box>
  <span
    class="w3-text-white no-transform w3-small w3-right w3-margin-right padding-vertical-xs"
    style="cursor: pointer; display: inline-block"
    (click)="showAdvancedSearch = true"
    >Advanced search</span
  >
</ng-template>

<dx-popup
  *ngIf="showAdvancedSearch"
  [(visible)]="showAdvancedSearch"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  width="90vw"
>
  <div *dxTemplate="let data of 'content'" class="advanced-search-content">
    <dx-scroll-view width="100%" height="100%">
      <app-companies-search
        (companySelected)="goToCompany($event)"
        (isSearching)="advancedSearchLoading = $event"
      >
      </app-companies-search>
    </dx-scroll-view>
    <dx-load-panel
      shadingColor="rgb(239, 236, 234,0.7)"
      message=""
      container=".advanced-search-content"
      [showIndicator]="true"
      [showPane]="true"
      [shading]="true"
      [visible]="advancedSearchLoading"
      [hideOnOutsideClick]="false"
    >
    </dx-load-panel>
  </div>
</dx-popup>
