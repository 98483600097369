<section>
  <!-- <header class="w3-cell-row w3-bottombar w3-border-theme-secondary">
    <div class="prefix-icon w3-cell w3-cell-middle w3-center" *ngIf="icon">
      <i class="w3-large" [ngClass]="icon"></i>
    </div>
    <div
      class="list-title w3-cell w3-cell-middle w3-large text-bold"
      [ngClass]="{ 'no-icon': !icon }"
    >
      {{ name }}
    </div>
    <div class="action-button w3-cell w3-cell-middle w3-center" *ngIf="action">
      <dx-button
        icon="fa-regular fa-circle-plus w3-large"
        (onClick)="onActionClicked()"
      ></dx-button>
    </div>
  </header> -->
  <header class="w3-border-bottom w3-bottombar w3-border-theme-secondary">
    <dx-toolbar class="w3-container">
      <dxi-item location="before">
        <div *dxTemplate class="toolbar-icon">
          <i *ngIf="icon" [ngClass]="icon" class="w3-large"></i>
        </div>
      </dxi-item>
      <dxi-item
        location="before"
        cssClass="list-title text-bold w3-large upper-case"
        [text]="name"
      ></dxi-item>
      <dxi-item
        location="after"
        *ngIf="action"
        widget="dxButton"
        cssClass="no-transform w3-small"
        [options]="{
          icon: 'fa-regular fa-circle-plus w3-large',
          stylingMode: 'text',
          onClick: onActionClicked
        }"
      ></dxi-item>
    </dx-toolbar>
  </header>
  <section *ngIf="items.length">
    <dx-list
      [ngClass]="{ 'prefix-icon': !!icon }"
      [items]="items"
      displayExpr="name"
      (onItemClick)="onItemClicked($event)"
    >
      <a
        *dxTemplate="let data of 'item'"
        [routerLink]="data.routerLink"
        class="no-transform"
      >
        <div class="toolbar-icon" *ngIf="!!data.type">
          <i
            *ngIf="data.type === 'manual'"
            class="fa-regular fa-list fa-lg"
          ></i>
          <span class="fa-stack stack-lg" *ngIf="data.type === 'dynamic'">
            <i class="fa-regular fa-list fa-stack-2x"></i>
            <i class="fa-solid fa-filter fa-stack-1x"></i>
          </span>
        </div>
        <div class="list-name">
          {{ data.name }}
        </div>
      </a>
      <!--  -->
    </dx-list>
  </section>
</section>
