<dx-popup
  [wrapperAttr]="{ class: 'list-editor-wrapper' }"
  [(visible)]="editorActive"
  (onHidden)="closeEditor($event)"
  [showCloseButton]="true"
  width="90vw"
  maxWidth="1440px"
  height="auto"
  maxHeight="90vh"
>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    [options]="{
      text: 'common.action.save' | translate,
      type: 'default',
      stylingMode: 'contained',
      width: 150,
      onClick: saveList
    }"
  >
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'" class="w3-container">
    <dx-scroll-view>
      <section class="w3-row header w3-margin-bottom">
        <div class="w3-twothird w3-bottombar w3-border-theme-secondary">
          <dx-toolbar>
            <dxi-item location="before">
              <div *dxTemplate>
                <i class="fa-regular fa-list fa-lg w3-large"></i>
              </div>
            </dxi-item>
            <dxi-item
              location="before"
              cssClass="list-title text-bold w3-large upper-case"
              [text]="
                (list === null
                  ? 'mylists.label.newlist'
                  : 'mylists.label.updatelist'
                ) | translate
              "
            ></dxi-item
          ></dx-toolbar>
        </div>
        <div class="w3-third">
          <div class="w3-right" *ngIf="!list">
            <dx-button
              [icon]="(isSolid.star ? 'fa-solid' : 'fa-regular') + ' fa-star'"
              (onClick)="isSolid.star = !isSolid.star"
            ></dx-button>
            <dx-button
              [icon]="
                isSolid.bell ? 'fa-solid fa-bell' : 'fa-regular fa-bell-slash'
              "
              (onClick)="isSolid.bell = !isSolid.bell"
            ></dx-button>
          </div>
        </div>
      </section>
      <ng-container *ngIf="editorActive">
        <section class="w3-row name w3-margin-bottom">
          <div class="w3-twothird">
            <div class="w3-medium text-bold required" translate>
              common.label.name
            </div>
            <dx-text-box [(value)]="name">
              <dx-validator>
                <dxi-validation-rule type="required"> </dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>
          <div class="w3-third"></div>
        </section>
        <section class="w3-row description w3-margin-bottom">
          <div class="w3-twothird">
            <div class="w3-medium text-bold required" translate>
              common.label.description
            </div>
            <dx-text-box [(value)]="description">
              <dx-validator>
                <dxi-validation-rule type="required"> </dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>
          <div class="w3-third"></div>
        </section>
      </ng-container>
      <section class="w3-row w3-section" *ngIf="!list">
        <div class="w3-rest">
          <dx-button
            [text]="'mylists.action.createDynamic' | translate"
            class="no-transform w3-small"
            stylingMode="text"
            [icon]="
              'fa-regular w3-text-theme-secondary ' +
              (isDynamicList ? 'fa-square-check' : 'fa-square')
            "
            (onClick)="isDynamicList = !isDynamicList"
          ></dx-button>
        </div>
      </section>
      <section class="w3-row filter w3-panel" *ngIf="isDynamicList">
        <app-companies-filter
          [(filter)]="filter"
          [filterUpdateOnChange]="true"
        ></app-companies-filter>
        <!-- <div class="w3-padding">
        <i class="fa-regular fa-circle-info dx-icon"></i>
        <span>
          All conditions that are grouped in an „AND“ group have to be met by
          the result, while only one condition in an „OR“ group has to be
          fulfilled.
        </span>
      </div>
      <dx-filter-builder
        [(value)]="filter"
        (onValueChanged)="filterChanged($event.value)"
        [fields]="filterFields"
      >
      </dx-filter-builder> -->
      </section>
    </dx-scroll-view>
  </div>
</dx-popup>
