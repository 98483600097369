import { environment } from '@app/environment';
import { deserializeObject, logger, serializeObject } from '@app/shared';
import { Column } from 'devextreme/ui/data_grid';
export type ListState = {
  allowedPageSizes: number[];
  filterPanel: {
    filterEnabled: boolean;
  };
  filterValue: unknown;
  pageIndex: number;
  pageSize: number;
  searchText: string;
  selectedRowKeys: (number | string)[];
  columns: Column[];
};

export type ListTypes = 'manual' | 'dynamic';

export interface BaseList<T extends ListTypes> {
  id: number;
  name: string;
  type: T;
  description: string | null;
  is_favorite: boolean;
  has_alerts: boolean;
  can_edit: boolean;
  is_preconfigured: boolean;
  config_json: unknown | null;
}

export type ManualList = BaseList<'manual'>;

export interface DynamicList extends BaseList<'dynamic'> {
  filter: unknown[];
}

export type ListType = ManualList | DynamicList;

export type APIList = {
  id: number | null;
  list_id: number;
  user_id: string | null;
  config_json: null | string;
  is_favorite: null | boolean;
  has_alerts: null | boolean;
  is_preconfigured?: boolean;
  lists: {
    id: number;
    name: string;
    type: null | 'manual' | 'dynamic';
    owner_id: null | string;
    description: null | string;
    query: unknown[] | string | null;
  };
};

export interface APIAdminList {
  id: number;
  description: string;
  name: string;
  owner_id: string;
  query: unknown[] | null | string;
  type: 'dynamic' | 'manual';
  users: {
    created_at: string;
    updated_at: string;
    id: string;
    email: string;
    first_name: string;
    last_name: string;
  };
}

export const sanitizeListState = ({ columns }: ListState): ListState => {
  return {
    allowedPageSizes: [100],
    columns,
    // columns: columns.map(
    //   ({ dataField, dataType, name, visible, visibleIndex, width }) => ({
    //     visible,
    //     visibleIndex,
    //     ...(dataField !== undefined ? { dataField } : {}),
    //     ...(dataType !== undefined ? { dataType } : {}),
    //     ...(name !== undefined ? { name } : {}),
    //     ...(width !== undefined ? { width } : {}),
    //   })
    // ),
    filterPanel: {
      filterEnabled: true,
    },
    filterValue: null,
    pageIndex: 0,
    pageSize: 100,
    searchText: '',
    selectedRowKeys: [],
  };
};

export const deserializeListState = (input: string | null) => {
  const deserialized = deserializeObject<{
    pageIndex: number;
    filterValue: unknown[] | null;
  } | null>(input, (key, value) => {
    if (
      value instanceof Array &&
      value.length === 3 &&
      ['last_funded_year', 'founded_at'].includes(value[0])
    ) {
      if (value[2] instanceof Array) {
        return [
          value[0],
          value[1],
          [new Date(value[2][0]), new Date(value[2][1])],
        ];
      } else {
        return [value[0], value[1], new Date(value[2])];
      }
    }
    return value;
  });

  return deserialized;
};

export const serializeListState = (listState: Partial<ListState>) => {
  const serialized = serializeObject({
    pageIndex: listState?.pageIndex || 0,
    filterValue: listState?.filterValue || null,
  });
  return serialized;
};

const getListConfig = (input: string | null) => {
  if (typeof input !== 'string' || input.length === 0) {
    return null;
  }
  try {
    return JSON.parse(input);
    // const config = JSON.parse(input) as { columns: Column[] };
    // return {
    //   ...config,
    //   columns: config.columns.map(({ sortOrder, sortIndex, ...column }) => ({
    //     ...column,
    //     // FIXME: Workaround to remove sort config for sector column
    //     ...(column.dataField === 'sectors'
    //       ? {}
    //       : {
    //           ...(typeof sortOrder === 'string' ? { sortOrder } : {}),
    //           ...(typeof sortIndex === 'number' ? { sortIndex } : {}),
    //         }),
    //   })),
    // };
  } catch (e) {
    logger.error(e);
    return null;
  }
};

const getListQuery = (input: string | unknown[] | null) => {
  if (typeof input !== 'string') {
    return input;
  } else if (input.length === 0) {
    return null;
  }
  try {
    return JSON.parse(input);
  } catch (e) {
    if (!environment.production) {
      console.error(e);
    }
    return null;
  }
};

export const toList = ({
  list_id,
  user_id,
  lists: { name, type, description, query, owner_id },
  config_json,
  is_favorite,
  has_alerts,
  is_preconfigured,
}: APIList): ListType =>
  ({
    id: list_id,
    name,
    type: type !== 'dynamic' ? 'manual' : 'dynamic',
    description,
    config_json: getListConfig(config_json),
    can_edit: owner_id === user_id,
    is_preconfigured: !!is_preconfigured,
    is_favorite: !!is_favorite,
    has_alerts: !!has_alerts,
    ...(type === 'dynamic' ? { filter: getListQuery(query) } : {}),
  } as ListType);

export const toAdminList = (input: APIAdminList, user_id: string) =>
  toList({
    id: input.id,
    list_id: input.id,
    user_id: user_id,
    config_json: null,
    is_favorite: null,
    has_alerts: null,
    lists: input,
  });
