export enum Permissions {
  EditCompanyData = 'EditCompanyData',
  EditSectorScoringFormularWeights = 'EditSectorScoringFormularWeights',
  ManageSectorKeywords = 'ManageSectorKeywords',
  Admin = 'Admin',
}

// export in ngx-permissions compatible type format
export const adminPermissions = [Permissions.Admin] as unknown as string[];

export const appPermissions = [
  Permissions.EditCompanyData,
  Permissions.Admin,
  Permissions.EditSectorScoringFormularWeights,
  Permissions.ManageSectorKeywords,
] as unknown as string[];
