import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListItem } from '@app/model';

@Component({
  selector: 'app-action-list',
  templateUrl: './action-list.component.html',
  styleUrls: ['./action-list.component.scss'],
})
export class ActionListComponent<T extends ListItem> implements OnInit {
  @Input() id!: number | string;
  @Input() name = '';
  @Input() icon: string | null = null;
  @Input() action: string | null = null;
  @Input() items: T[] = [];
  @Input() limitItems: number | null = null;

  @Output() itemClicked = new EventEmitter<T>();
  @Output() actionClicked = new EventEmitter<void>();

  ngOnInit(): void {}

  onItemClicked(e: { itemData: T }) {
    this.itemClicked.emit(e.itemData);
  }

  onActionClicked = () => {
    this.actionClicked.emit();
  };
}
