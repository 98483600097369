import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, CompanyService, Permissions } from '@app/core';
import { AlertItem, CompanyShortInfo } from '@app/model';
import { EMPTY, finalize } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() alerts: AlertItem[] = [];
  @Output() toggleMenu = new EventEmitter<void>();
  @Output() showAlerts = new EventEmitter<void>();

  public options = [
    { name: 'SIGN OUT', id: '/login' },
    { name: 'CHANGE PASSWORD', id: '/change-password' },
    ...(this.auth.checkPermission(
      Permissions.EditSectorScoringFormularWeights,
      Permissions.ManageSectorKeywords
    )
      ? [
          {
            name: 'BACKEND / ALL LISTS',
            id: '/my-lists/all',
            isLink: true,
          },
          {
            name: 'BACKEND / SECTORS',
            id: '/backend/sectors',
            isLink: true,
          },
        ]
      : []),
    {
      name: 'SEND FEEDBACK',
      id: 'https://spryfox.atlassian.net/servicedesk/customer/portal/6',
      isLink: true,
      isExternal: true,
    },
  ];
  public showMobileSearch = false;
  public showAdvancedSearch = false;
  public userName$ = this.auth.username$;
  public companies = this.companyService.getCompaniesAutoComplete();
  public autocompleteLoading = false;
  public unreadAlerts = 0;
  public advancedSearchLoading = false;

  constructor(
    private router: Router,
    private companyService: CompanyService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.companies.on(
      'loadingChanged',
      (isLoading: boolean) => (this.autocompleteLoading = isLoading)
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['alerts']) {
      this.unreadAlerts = this.alerts.filter((x) => x.unread).length;
    }
  }

  ngOnDestroy() {
    this.companies.off('loadingChanged');
  }

  optionClicked = (e: {
    itemData: { name: string; id: string; isExternal?: boolean };
  }) => {
    (e.itemData.id === '/login' ? this.auth.logout() : EMPTY)
      .pipe(
        finalize(() => {
          if (!e.itemData.isExternal) {
            this.router.navigateByUrl(e.itemData.id);
          }
        })
      )
      .subscribe();
  };

  getSectorList(company: CompanyShortInfo) {
    return company.companies_subsectors
      .filter((x) => x.subsector_id !== null)
      .map((x) => x.subsectors.name)
      .join(', ');
  }

  goToCompany(companyId: string) {
    this.showAdvancedSearch = false;
    this.router.navigateByUrl(
      `/companies/${encodeURIComponent(companyId)}/overview`
    );
  }

  toggle = () => {
    this.toggleMenu.emit();
  };

  toggleLoadPanel = (e: unknown) => {};
}
