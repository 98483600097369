import { CompanyService } from '@app/core';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { companyFields, DynamicList, ListType } from '@app/model';

@Component({
  selector: 'app-list-editor',
  templateUrl: './list-editor.component.html',
  styleUrls: ['./list-editor.component.scss'],
})
export class ListEditorComponent implements OnChanges {
  @Input() list: ListType | null = null;
  @Input() editorActive = false;
  @Output() editorActiveChange = new EventEmitter<boolean>();

  public filterFields = companyFields;

  public isSolid = {
    bell: false,
    star: false,
  };
  public isDynamicList = false;
  public filter: unknown[] | null = null;

  public description = '';
  public name = '';

  constructor(private companyService: CompanyService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.editorActive && (changes['editorActive'] || changes['list'])) {
      if (this.list !== null) {
        this.description = this.list.description || '';
        this.name = this.list.name;
        this.isDynamicList = this.list.type === 'dynamic';
        if (this.isDynamicList) {
          this.filter = (this.list as DynamicList).filter;
        }
        this.isSolid.bell = !!this.list.has_alerts;
        this.isSolid.star = !!this.list.is_favorite;
      } else {
        this.description = '';
        this.name = '';
        this.isDynamicList = false;
        this.filter = null;
        this.isSolid.bell = false;
        this.isSolid.star = false;
      }
    }
  }

  saveList = (e: unknown) => {
    if ((e as any).validationGroup.validate().isValid) {
      if (this.list) {
        // edit mode
        this.companyService
          .updateList(this.list.id, {
            description: this.description,
            name: this.name,
            filter: this.isDynamicList ? this.filter : undefined,
          })
          .subscribe(() => this.closeEditor());
      } else if (this.name.length && this.description.length) {
        // new list mode
        this.companyService
          .createCompanyList(
            this.name,
            this.isDynamicList ? 'dynamic' : 'manual',
            this.description,
            this.isSolid.star,
            this.isSolid.bell,
            this.isDynamicList ? this.filter : undefined
          )
          .subscribe(() => this.closeEditor());
      }
    }
  };

  closeEditor(e?: unknown) {
    this.editorActive = false;
    this.editorActiveChange.emit(this.editorActive);
  }
}
