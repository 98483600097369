<header class="w3-section menu-padding">
  <dx-toolbar class="w3-container margin-top-sm">
    <dxi-item location="before">
      <div *dxTemplate class="toolbar-icon">
        <img
          class="five-arrow-logo"
          src="assets/img/Five_Arrows_Logo_blue.svg"
        />
      </div>
    </dxi-item>
    <dxi-item
      location="before"
      cssClass="list-title text-bold w3-large upper-case"
      text="Hunter"
    ></dxi-item
  ></dx-toolbar>
</header>
<dx-scroll-view height="80vh">
  <section class="w3-section padding-vertical-sm" *ngFor="let item of menu">
    <app-action-list
      [id]="item.id"
      [name]="item.name"
      [icon]="item.icon || null"
      [action]="item.action || null"
      [items]="item.list"
      [limitItems]="item.limit || null"
      (itemClicked)="listItemClicked(item.id, $event)"
      (actionClicked)="editorActive = true"
    ></app-action-list>
  </section>
  <!-- <section class="w3-section padding-vertical-sm">
    <dx-toolbar class="w3-container margin-top-sm">
      <dxi-item location="before">
        <div *dxTemplate class="toolbar-icon">

        </div>
      </dxi-item>
      <dxi-item
        location="before"
        cssClass="list-title text-bold w3-large upper-case"
        ><div *dxTemplate>
          <a
            class="feedback-link text-bold w3-large"
            href="https://spryfox.atlassian.net/servicedesk/customer/portal/6"
            >Send Feedback</a
          >
        </div></dxi-item
      ></dx-toolbar
    >
  </section> -->
</dx-scroll-view>
<app-list-editor
  [(editorActive)]="editorActive"
  [list]="null"
></app-list-editor>
