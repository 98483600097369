import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@app/environment';
import { mergeMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    return this.auth.token$.pipe(
      mergeMap((token) => {
        if (req.url.startsWith(environment.apiBase) && token) {
          const reqWithAuth = req.clone({
            setHeaders: { Authorization: token },
          });
          return next.handle(reqWithAuth);
        }
        return next.handle(req);
      })
    );
  }
}
