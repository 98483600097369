import DataSource from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data';
import { firstValueFrom, ReplaySubject, Subject } from 'rxjs';
import { DataApiAdapter, DataSourceConfig, DataId, DataSet } from './interface';
import { Subscription } from 'rxjs';

export class DataSourceAdapter<
  ReadType extends DataSet = any,
  WriteType extends DataSet = ReadType
> {
  private dataLoaded$ = new Subject<ReadType[]>();
  private dataItems$ = new ReplaySubject<ReadType[]>(1);
  private dataSource!: DataSource<ReadType, DataId>;

  private _sub = new Subscription();

  constructor(private api: DataApiAdapter<ReadType>, config: DataSourceConfig) {
    this._sub.add(
      this.dataLoaded$.subscribe((result) => this.dataItems$.next(result))
    );
    this.dataSource = new DataSource<ReadType, DataId>({
      key: 'id',
      byKey: (key) => {
        if (!this.api.select) {
          throw new Error('not implemented');
        }
        return firstValueFrom(this.api.select(config.path, key));
      },
      load: (loadOptions) => {
        if (!this.api.selectAll) {
          throw new Error('not implemented');
        }
        return firstValueFrom(this.api.selectAll(config.path, loadOptions));
      },
      insert: (values) => {
        if (!this.api.insert) {
          throw new Error('not implemented');
        }
        return firstValueFrom(this.api.insert(config.path, values));
      },
      update: (key, values) => {
        if (!this.api.update) {
          throw new Error('not implemented');
        }
        return firstValueFrom(this.api.update(config.path, key, values));
      },
      remove: (key) => {
        if (!this.api.remove) {
          throw new Error('not implemented');
        }
        return firstValueFrom(this.api.remove(config.path, key));
      },
      onLoaded: (result, options) => {
        this.dataLoaded$.next(result);
      },
      ...config,
    });
  }

  public releaseSource() {
    this._sub.unsubscribe();
  }

  public asObservable() {
    return this.dataItems$.asObservable();
  }
  public asDataSource() {
    return this.dataSource;
  }

  public getItems(): ReadType[] {
    return this.dataSource.items();
  }

  selectAll(options: LoadOptions) {
    this.dataSource.store().load(options);
  }
  select(key: DataId) {
    throw new Error('not implemented');
  }
  update(key: DataId, data: Partial<WriteType>) {
    throw new Error('not implemented');
  }
  insert(data: WriteType) {
    throw new Error('not implemented');
  }
  remove(key: DataId) {
    throw new Error('not implemented');
  }
}
