export { CoreModule } from './core.module';

export {
  LanguageService,
  LANGUAGE_LOADER_CONFIG_TOKEN,
  LanguageLoaderConfig,
} from './lang';

export { AuthService, AuthGuard, Permissions } from './auth';
export * from './service';
export * from './data';
export * from './config.vars';
