import { AlertListComponent } from './alert-list/alert-list.component';
import { BreadCrumbComponent } from './bread-crumb/bread-crumb.component';
import { ActionListComponent } from './action-list/action-list.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SideMenuLayoutComponent } from './side-menu-layout/side-menu-layout.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { UnauthenticatedComponent } from './unauthenticated/unauthenticated.component';
import { ListEditorComponent } from './list-editor/list-editor.component';
import { CompaniesSearchComponent } from './companies-search/companies-search.component';
import { CompaniesFilterComponent } from './companies-filter/companies-filter.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

const sharedComponents = [
  AlertListComponent,
  BreadCrumbComponent,
  ListEditorComponent,
  ActionListComponent,
  SideMenuLayoutComponent,
  SideMenuComponent,
  HeaderComponent,
  FooterComponent,
  UnauthenticatedComponent,
  CompaniesSearchComponent,
  CompaniesFilterComponent,
  ChangePasswordComponent,
];

export {
  sharedComponents,
  ListEditorComponent,
  AlertListComponent,
  BreadCrumbComponent,
  ActionListComponent,
  SideMenuLayoutComponent,
  SideMenuComponent,
  HeaderComponent,
  FooterComponent,
  UnauthenticatedComponent,
  CompaniesSearchComponent,
  CompaniesFilterComponent,
  ChangePasswordComponent,
};
