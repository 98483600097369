import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@app/environment';
import {
  APIAdminList,
  ApiCompany,
  ApiCompanyComment,
  ApiCompanyListItem,
  APICountry,
  APIDownloadList,
  APIList,
  ApiOverwrite,
  ApiSector,
  APIUserPageVisits,
  Company,
  companyColumnMap,
  companyColumnUnMap,
  CompanyDetail,
  companyRelationsMap,
  CompanyReviewScore,
  CompanyShortInfo,
  isCondition,
  ListType,
  mapFilterColumns,
  MenuList,
  OverwriteConfig,
  prepareCountries,
  SubSector,
  toAdminList,
  toCompanyDetail,
  toCompanyListItem,
  toList,
  toOverwriteDataset,
  toSector,
  User,
} from '@app/model';

import DataSource from 'devextreme/data/data_source';
import {
  BehaviorSubject,
  combineLatest,
  merge,
  Observable,
  of,
  ReplaySubject,
  Subject,
  timer,
} from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  finalize,
  ignoreElements,
  map,
  mergeMap,
  retry,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { AuthService } from '../auth';
import {
  AutocompleteDataSource,
  DataSourceAdapter,
  MockDataSource,
  Paginator,
} from '../data';
import { logger } from '@app/shared';

const getCompaniesAutocompleteDataSource = (http: HttpClient) =>
  new AutocompleteDataSource<CompanyShortInfo>(http, 'company', {
    path: `${environment.apiBase}/autocomplete`,
    paginate: false,
    postProcess: (result) =>
      result
        .filter((x) => !!x.name || !!x.legal_name)
        .map((x) => ({ ...x, name: x.name || x.legal_name }))
        .sort((a, b) =>
          a.name.toUpperCase() > b.name.toUpperCase()
            ? 1
            : a.name.toUpperCase() < b.name.toUpperCase()
            ? -1
            : 0
        ),
  }).asDataSource();

const getUserAutocompleteDataSource = (http: HttpClient) =>
  new AutocompleteDataSource<User & { name: string }>(http, 'user', {
    path: `${environment.apiBase}/autocomplete`,
    byKey: (key) => Promise.resolve(key as unknown as User),
    paginate: false,
    map: (user: User & { name?: string }) => ({
      ...user,
      name:
        typeof user.name === 'string'
          ? user.name
          : user.first_name + ' ' + user.last_name,
    }),
  }).asDataSource();

const getCompaniesDataSource = (
  http: HttpClient,
  listId: number | null = null
) =>
  new DataSourceAdapter<Company>(
    {
      selectAll: (path, options) => {
        if (
          !options ||
          typeof options.skip !== 'number' ||
          typeof options.take !== 'number'
        ) {
          logger.warn('pagination options missing', { path, options });
          // return throwError(() => new Error('Malformed Request'));
        }

        type OrderBy = {
          [field: string]: { sort: 'asc' | 'desc'; nulls: 'last' } | OrderBy;
        };

        const getSort = ({
          selector,
          desc,
        }: {
          selector: string;
          desc: boolean;
        }): OrderBy => ({
          [companyRelationsMap[selector as keyof typeof companyRelationsMap] ||
          selector]: {
            sort: desc ? 'desc' : 'asc',
            nulls: 'last',
          },
        });

        const orderBy =
          options && options.sort instanceof Array
            ? (options.sort as { selector: string; desc: boolean }[]).reduce(
                (acc, cur) => ({
                  ...acc,
                  ...(companyRelationsMap[
                    cur.selector as keyof typeof companyRelationsMap
                  ]
                    ? {
                        companies_latest: getSort(cur),
                      }
                    : getSort(cur)),
                }),
                {} as OrderBy
              )
            : null;

        if (
          options &&
          options.sort instanceof Array &&
          options.sort?.length > 1
        ) {
          logger.warn('POSSIBLE SORT OVERWRITE');
        }

        if (options && options.take && options.take !== 100) {
          logger.warn('PageSize not 100');
        }

        return http
          .post<{
            data: ApiCompanyListItem[];
            totalCount: number;
          }>(path, {
            method: 'fetchListOfCompany',
            data: {
              page:
                (options &&
                  options.skip &&
                  options.take &&
                  options.skip / options.take) ||
                0,
              resultsPerPage: 100,
              requestType: 'normal', // 'detailed',
              ...(orderBy ? { orderBy } : {}),
              ...(typeof listId === 'number' ? { listId } : {}),
              ...(options && options.filter instanceof Array
                ? {
                    filter: isCondition(options.filter)
                      ? [mapFilterColumns(options.filter, companyColumnMap)]
                      : mapFilterColumns(options.filter, companyColumnMap),
                  }
                : {}),
            },
          })
          .pipe(
            retry(1),
            catchError((e: HttpErrorResponse) => {
              throw new Error(
                e.status === 504
                  ? 'Too many results. Please specify more detailed search criteria.'
                  : e.statusText || e.message
              );
            })
          );
      },
    },
    {
      path: `${environment.apiBase}/fetchListOfCompany`,
      key: 'business_id',
      postProcess: (data: ApiCompanyListItem[]) => data.map(toCompanyListItem),
    }
  ).asDataSource();

@Injectable({ providedIn: 'root' })
export class CompanyService {
  private refreshLists = new Subject<boolean>();
  private refreshRecent = new Subject<void>();
  private refreshCompany = new Subject<string>();
  private selectedCompanyId = new ReplaySubject<string>(1);
  private selectedListId = new ReplaySubject<number | null>(1);
  private companyLoading = new ReplaySubject<boolean>(1);
  private companyListLoading = new BehaviorSubject<boolean>(false);
  private lists = new ReplaySubject<ListType[]>(1);
  private preconfiguredLists = new ReplaySubject<ListType[]>(1);

  private companyListSource: DataSource | null = null;
  private companyListId: number | null = null;

  public readonly companyLoading$ = this.companyLoading.asObservable();

  public readonly companyListLoading$ = this.companyListLoading.pipe(
    distinctUntilChanged(),
    delay(0) // force value change to next tick to prevent change detection error
  );

  public readonly company$: Observable<CompanyDetail> = merge(
    this.refreshCompany,
    this.selectedCompanyId.pipe(distinctUntilChanged())
  ).pipe(
    tap(() => this.companyLoading.next(true)),
    switchMap((companyId) =>
      this.http
        .post<ApiCompany>(`${environment.apiBase}/fetchCompany`, {
          method: 'fetchCompany',
          data: {
            companyId,
          },
        })
        .pipe(
          map((result) => toCompanyDetail(result)),
          finalize(() => {
            this.refreshRecent.next();
            this.companyLoading.next(false);
          })
        )
    ),
    shareReplay(1)
  );

  public readonly lastUpdate$ = this.http
    .post<string>(`${environment.apiBase}/fetchLatestDataLoad`, {
      method: 'fetchLatestDataLoad',
    })
    .pipe(shareReplay(1));

  public readonly sectors$ = this.http
    .post<ApiSector[]>(`${environment.apiBase}/fetchSector`, {
      method: 'fetchSector',
    })
    .pipe(
      map((sectors) => sectors.map(toSector)),
      shareReplay(1)
    );

  public readonly subsectors$ = this.sectors$.pipe(
    map((sectors) =>
      sectors.reduce((acc, cur) => [...acc, ...cur.items], [] as SubSector[])
    )
  );

  public readonly countryCodes$ = this.http
    .post<APICountry[]>(`${environment.apiBase}/fetchCountryCode`, {
      method: 'fetchCountryCode',
    })
    .pipe(map(prepareCountries), shareReplay(1));

  public readonly lists$ = this.lists.pipe(
    map((list) =>
      list.sort((a, b) =>
        a.name.toUpperCase() > b.name.toUpperCase()
          ? 1
          : b.name.toUpperCase() > a.name.toUpperCase()
          ? -1
          : 0
      )
    )
  );

  public readonly preconfiguredLists$ = this.preconfiguredLists.pipe(
    map((list) =>
      list.sort((a, b) =>
        a.name.toUpperCase() > b.name.toUpperCase()
          ? 1
          : b.name.toUpperCase() > a.name.toUpperCase()
          ? -1
          : 0
      )
    )
  );

  public readonly allLists$ = combineLatest([
    this.lists$,
    this.preconfiguredLists$,
  ]).pipe(
    map(([lists, preconfiguredLists]) => [...lists, ...preconfiguredLists]),
    shareReplay(1)
  );

  public selectedList$ = this.selectedListId.pipe(
    switchMap((listId) =>
      this.allLists$.pipe(
        map(
          (lists) =>
            (listId && lists.find((list) => list.id === listId)) || null
        )
      )
    ),
    shareReplay(1)
  );

  public readonly favoriteLists$ = this.lists$.pipe(
    map((lists) => lists.filter((list) => list.is_favorite)),
    shareReplay(1)
  );

  public readonly manualLists$ = this.lists$.pipe(
    map((lists) => lists.filter((list) => list.type === 'manual')),
    shareReplay(1)
  );

  private readonly recent$ = merge(
    this.getRecentCompanies(),
    this.refreshRecent.pipe(switchMap(() => this.getRecentCompanies()))
  ).pipe(shareReplay(1));

  public readonly sideMenu$: Observable<MenuList[]> = combineLatest([
    this.favoriteLists$,
    this.recent$,
  ]).pipe(
    map(([favoriteLists, recentCompanies]) => [
      {
        id: 1,
        icon: 'fa-regular fa-star',
        action: 'fa-regular fa-circle-plus',
        name: 'Favourite Lists',
        list: favoriteLists.map((list) => ({
          ...list,
          routerLink: `/my-lists/${list.id}`,
        })),
      },
      {
        id: 2,
        icon: 'fa-regular fa-buildings',
        name: 'Recent',
        list: recentCompanies
          .map((x: any) => ({
            id: x.companies.business_id,
            name: x.companies.name,
            routerLink: `/companies/${encodeURIComponent(
              x.companies.business_id
            )}/overview`,
          }))
          .slice(0, 10),
      },
    ]),
    shareReplay(1)
  );

  constructor(private http: HttpClient, private auth: AuthService) {
    merge(this.sectors$, this.countryCodes$).pipe(ignoreElements()).subscribe();
    merge(this.auth.isAuthenticated$, this.refreshLists)
      .pipe(
        switchMap((reload) =>
          reload
            ? this.http
                .post<
                  [APIList[], { list_id: number; lists: APIList['lists'] }[]]
                >(`${environment.apiBase}/fetchListOfCompanyByUser`, {
                  method: 'fetchListOfCompanyByUser',
                  data: { favoritesOnly: false },
                })
                .pipe(
                  map(([lists, preconfiguredLists]) => [
                    lists.map(toList),
                    preconfiguredLists.map((list) =>
                      toList({
                        ...list,
                        is_preconfigured: true,
                        id: list.list_id,
                        user_id: null,
                        config_json: null,
                        is_favorite: null,
                        has_alerts: null,
                      })
                    ),
                  ])
                )
            : of([[] as ListType[], [] as ListType[]])
        ),
        catchError((e) => {
          logger.error(e);
          return of([[] as ListType[], [] as ListType[]]);
        }),
        map(([lists, preconfiguredLists]) => {
          const mapFn = (list: ListType) =>
            list.type === 'dynamic' && list.filter instanceof Array
              ? {
                  ...list,
                  filter: mapFilterColumns(
                    (list.filter.length === 1
                      ? list.filter[0]
                      : list.filter) as any[],
                    companyColumnUnMap
                  ),
                }
              : list;
          return [lists.map(mapFn), preconfiguredLists.map(mapFn)];
        })
      )
      .subscribe(([lists, preconfiguredLists]) => {
        this.lists.next(lists);
        this.preconfiguredLists.next(preconfiguredLists);
      });
  }

  public downloadList(listId: number) {
    // this.companyListLoading.next(true);
    const pollExport = (
      input: APIDownloadList
    ): Observable<{ url: string; filename: string }> =>
      this.http
        .post<APIDownloadList>(`${environment.apiBase}/download`, {
          method: 'pollExportStatus',
          data: { exportIdentifier: input.exportIdentifier },
        })
        .pipe(
          mergeMap((result) =>
            input.isComplete
              ? of({
                  url: result.presignedURL || '',
                  filename: result.filename || '',
                })
              : timer(2000).pipe(mergeMap(() => pollExport(result)))
          )
        );

    return this.http
      .post<APIDownloadList>(`${environment.apiBase}/download`, {
        method: 'downloadList',
        data: { listId },
      })
      .pipe(
        mergeMap((result) => pollExport(result)),
        mergeMap(({ url, filename }) =>
          this.http.get(url, { responseType: 'blob' }).pipe(
            map((blob) => ({
              blob,
              filename,
            }))
          )
        )
        // finalize(() => this.companyListLoading.next(false))
      );
  }

  private getRecentCompanies() {
    return this.http
      .post<APIUserPageVisits[]>(`${environment.apiBase}/userPageVisit`, {
        method: 'userPageVisit',
        data: {
          requestType: 'read',
        },
      })
      .pipe(catchError(() => of([])));
  }

  public getCompanyListPaginator(listId: number | null) {
    return !!listId && listId === this.companyListId
      ? new Paginator(this.companyListSource)
      : new Paginator(null);
  }

  public setCompanyLoading(loading: boolean) {
    this.companyLoading.next(loading);
  }

  public selectList(listId: number | null) {
    this.selectedListId.next(listId);
  }

  public selectCompany(companyId: string) {
    this.selectedCompanyId.next(companyId);
  }

  public reloadCompany(companyId: string) {
    this.refreshCompany.next(companyId);
  }

  public createCompanyList(
    name: string,
    type: 'manual' | 'dynamic',
    description: string,
    isFavorite = false,
    hasAlerts = false,
    filter?: unknown[] | null
  ) {
    const query =
      !!filter &&
      JSON.stringify(
        isCondition(filter)
          ? [mapFilterColumns(filter, companyColumnMap)]
          : mapFilterColumns(filter, companyColumnMap)
      );
    return this.http
      .post<APIList>(`${environment.apiBase}/insertList`, {
        method: 'insertList',
        data: {
          name,
          type,
          description,
          isFavorite,
          hasAlerts,
          ...(query ? { query } : {}),
        },
      })
      .pipe(finalize(() => this.refreshLists.next(true)));
  }

  public addCompanyToList(companyId: string[], listId: number) {
    return this.http.post<unknown>(`${environment.apiBase}/companyList`, {
      method: 'companyList',
      data: {
        requestType: 'create',
        companyId,
        listId,
      },
    });
  }

  public addCompanyListToList(filter: unknown[] | null, listId: number) {
    return this.http.post<unknown>(
      `${environment.apiBase}/assignDynamicListToManualList`,
      {
        method: 'assignDynamicListToManualList',
        data: {
          filter:
            filter instanceof Array && filter[0] instanceof Array
              ? filter
              : [filter],
          listId,
        },
      }
    );
  }

  public removeCompanyFromList(companyId: string[], listId: number) {
    return this.http.post<unknown>(`${environment.apiBase}/companyList`, {
      method: 'companyList',
      data: {
        requestType: 'delete',
        companyId,
        listId,
      },
    });
  }

  public deleteList(listId: number) {
    return this.http
      .post<unknown>(`${environment.apiBase}/modifyList`, {
        method: 'modifyList',
        data: {
          requestType: 'delete',
          listId,
        },
      })
      .pipe(finalize(() => this.refreshLists.next(true)));
  }

  public updateList(
    listId: number,
    {
      ownerId,
      name,
      description,
      filter,
    }: {
      ownerId?: string;
      name?: string;
      description?: string;
      filter?: unknown[] | null;
    }
  ) {
    const query =
      !!filter &&
      JSON.stringify(
        isCondition(filter)
          ? [mapFilterColumns(filter, companyColumnMap)]
          : mapFilterColumns(filter, companyColumnMap)
      );

    return this.http
      .post<unknown>(`${environment.apiBase}/modifyList`, {
        method: 'modifyList',
        data: {
          requestType: 'update',
          listId,
          ...(ownerId ? { ownerId } : {}),
          ...(name ? { name } : {}),
          ...(description ? { description } : {}),
          ...(query ? { query } : {}),
        },
      })
      .pipe(finalize(() => this.refreshLists.next(true)));
  }

  public getAdminLists() {
    return this.http
      .post<APIAdminList[]>(`${environment.apiBase}/list`, {
        method: 'list',
        data: { requestType: 'read' },
      })
      .pipe(
        mergeMap((result) =>
          this.auth.userid$.pipe(
            map((userId) =>
              result.map((list) => toAdminList(list, userId as string))
            )
          )
        )
      );
  }

  public shareList(listId: number, userId: string) {
    return this.http
      .post<unknown>(`${environment.apiBase}/listUser`, {
        method: 'listUser',
        data: { requestType: 'create', listId, userId: [userId] },
      })
      .pipe(
        catchError((e) => {
          throw new Error(e.error?.error || e.message);
        })
      );
  }

  public updateUserList(
    listId: number,
    params: Partial<{
      isFavourite: boolean;
      hasAlerts: boolean;
      configJson: unknown;
    }>
  ) {
    return this.http
      .post<unknown>(`${environment.apiBase}/listUser`, {
        method: 'listUser',
        data: { requestType: 'update', listId, ...params },
      })
      .pipe(finalize(() => this.refreshLists.next(true)));
  }

  public addReviewStatus(
    reviewedCompanyId: string,
    status: string,
    comment: string
    // log?: unknown,
    // statusLog?: unknown
  ) {
    return this.http
      .post<unknown>(`${environment.apiBase}/insertReviewStatus`, {
        method: 'insertReviewStatus',
        data: {
          reviewedCompanyId,
          status,
          comment,
        },
      })
      .pipe(finalize(() => this.refreshCompany.next(reviewedCompanyId)));
  }

  public addComment(commentedCompanyId: string, text: string) {
    return this.http.post<ApiCompanyComment>(
      `${environment.apiBase}/insertComment`,
      {
        method: 'insertComment',
        data: {
          commentedCompanyId,
          text,
        },
      }
    );
    // .pipe(finalize(() => this.refreshCompany.next(commentedCompanyId)));
  }

  public saveReviewScore(score: CompanyReviewScore) {
    return this.http.post<unknown>(`${environment.apiBase}/reviewScore`, {
      method: 'reviewScore',
      data: {
        requestType: score.id === null ? 'create' : 'update',
        ...(score.id === null
          ? { companyId: score.company_id, criterionId: score.criterion_id }
          : { reviewScoreId: score.id }),
        comment: score.comment || '',
        reasonForExclusion: !!score.reason_for_exclusion,
        score: score.score,
      },
    });
  }

  public asssignUser(user_id: string | null) {
    this.companyLoading.next(true);
    return this.company$.pipe(
      take(1),
      mergeMap(({ business_id, assigned_user }) =>
        this.http
          .post<unknown>(`${environment.apiBase}/api`, {
            method: 'assigneeCompany',
            data:
              user_id === null
                ? {
                    requestType: 'delete',
                    company_id: business_id,
                  }
                : {
                    requestType: assigned_user === null ? 'create' : 'update',
                    company_id: business_id,
                    user_id,
                  },
          })
          .pipe(finalize(() => this.reloadCompany(business_id)))
      )
    );
  }

  public updateOverwrites(entity: string, companyId: string, input: any) {
    this.companyLoading.next(true);
    return this.http
      .post<ApiOverwrite>(`${environment.apiBase}/api`, {
        method: entity,
        data: { requestType: 'update', ...input },
      })
      .pipe(finalize(() => this.reloadCompany(companyId)));
  }

  public getOverwrites<
    T extends { overwrite_reference: { [property: string]: string } | null }
  >(config: OverwriteConfig[], dataset: T) {
    const { overwrite_reference, ...data } = dataset;
    this.companyLoading.next(true);
    return (
      !!overwrite_reference && !!Object.keys(overwrite_reference).length
        ? this.http.post<ApiOverwrite[]>(`${environment.apiBase}/overwrite`, {
            method: 'overwrite',
            data: {
              requestType: 'read',
              id: Object.values(overwrite_reference),
            },
          })
        : of([])
    ).pipe(
      map((overwrites) => toOverwriteDataset(config, overwrites, data)),
      finalize(() => this.companyLoading.next(false))
    );
  }

  public getCompaniesAsDataSource(listId?: number | null) {
    const datasource = getCompaniesDataSource(this.http, listId);
    if (this.companyListSource !== null) {
      this.companyListSource.off('loadingChanged');
      this.companyListLoading.next(false);
    }
    if (typeof listId === 'number') {
      this.companyListId = listId;
      this.companyListSource = datasource;
      this.companyListSource.on('loadingChanged', (isLoading: boolean) =>
        this.companyListLoading.next(isLoading)
      );
    }

    return datasource;
  }

  public getCompaniesAutoComplete() {
    return getCompaniesAutocompleteDataSource(this.http);
  }

  public getUsersAsDataSource() {
    return new MockDataSource<User>([], {
      path: 'user',
    }).asDataSource();
  }

  public getUsersAutoComplete() {
    return getUserAutocompleteDataSource(this.http);
  }
}
