<ng-container
  *ngIf="enableAdvancedFilter; then filterTabs; else simpleFilterTemplate"
></ng-container>
<section class="w3-padding" *ngIf="!filterUpdateOnChange">
  <dx-button
    [disabled]="!filterEnabled"
    type="default"
    text="Apply filter"
    (onClick)="updateFilter()"
  ></dx-button>
</section>

<ng-template #filterTabs>
  <dx-tab-panel
    [items]="['Simple Filter', 'Advanced Filter']"
    (onSelectionChanged)="toggleAdvancedFilter()"
    [animationEnabled]="true"
    #tabs
  >
    <div *dxTemplate="let tab of 'item'">
      <ng-container
        *ngIf="tabs.selectedIndex === 0"
        [ngTemplateOutlet]="simpleFilterTemplate"
      ></ng-container>
      <ng-container
        *ngIf="tabs.selectedIndex === 1"
        [ngTemplateOutlet]="advancedFilterTemplate"
      ></ng-container>
    </div>
  </dx-tab-panel>
</ng-template>

<ng-template #simpleFilterTemplate>
  <section class="w3-section filter">
    <div class="w3-row filter-row">
      <div class="w3-col m12 l3 w3-padding">
        <div class="w3-padding w3-theme-tertiary">
          <div class="w3-margin-bottom" translate>common.label.name</div>
          <dx-text-box
            class="w3-theme-tertiary"
            [showClearButton]="true"
            [(value)]="simpleFilterValues.name"
            (onValueChanged)="simpleFilterChanged('name', 'contains')"
            (onEnterKey)="enterPressed()"
          ></dx-text-box>
        </div>
      </div>
      <div class="w3-col m12 l3 w3-padding">
        <div class="w3-padding w3-theme-tertiary">
          <div class="w3-margin-bottom" translate>Subsector</div>
          <dx-tag-box
            *ngIf="sectors$ | async as sectors"
            class="w3-theme-tertiary"
            [maxDisplayedTags]="1"
            [showClearButton]="true"
            [showSelectionControls]="true"
            [searchEnabled]="true"
            [dataSource]="sectors"
            valueExpr="id"
            displayExpr="name"
            [(value)]="simpleFilterValues.sectors"
            (onValueChanged)="simpleFilterChanged('sectors', 'anyof', $event)"
          >
          </dx-tag-box>
        </div>
      </div>
      <div class="w3-col m12 l3 w3-padding">
        <div class="w3-padding w3-theme-tertiary">
          <div class="w3-margin-bottom" translate>Ownership type</div>
          <dx-select-box
            class="w3-theme-tertiary"
            [items]="[
              'Private',
              'Venture Capital',
              'Private Equity',
              'Public Sub',
              'Non-Profit',
              'Public',
              'Seed',
              'Private Sub',
              'Gov Agency',
              'Joint Venture',
              'Edu',
              'Other'
            ]"
            [showClearButton]="true"
            [(value)]="simpleFilterValues.ownership_type"
            (onValueChanged)="simpleFilterChanged('ownership_type', '=')"
          ></dx-select-box>
        </div>
      </div>
      <div class="w3-col m12 l3 w3-padding">
        <div class="w3-padding w3-theme-tertiary">
          <div class="w3-margin-bottom" translate>Investor</div>
          <dx-text-box
            class="w3-theme-tertiary"
            [showClearButton]="true"
            [(value)]="simpleFilterValues.investors"
            (onValueChanged)="simpleFilterChanged('investors', 'contains')"
            (onEnterKey)="enterPressed()"
          ></dx-text-box>
        </div>
      </div>
    </div>
    <div class="w3-row filter-row">
      <div class="w3-col m12 l3 w3-padding">
        <div class="w3-padding w3-theme-tertiary">
          <div class="w3-margin-bottom" translate>Keywords</div>
          <dx-tag-box
            class="w3-theme-tertiary"
            [showClearButton]="true"
            [acceptCustomValue]="true"
            placeholder="Enter Keywords"
            [showDropDownButton]="false"
            [items]="simpleFilterValues.keywords"
            [(value)]="simpleFilterValues.keywords"
            [dropDownOptions]="{
              wrapperAttr: { class: 'keywords-drop-down' }
            }"
            (onSelectionChanged)="
              simpleFilterChanged('keywords', 'contains', $event)
            "
          ></dx-tag-box>
        </div>
      </div>
      <div class="w3-col m12 l3 w3-padding">
        <div class="w3-padding w3-theme-tertiary">
          <div class="w3-margin-bottom" translate>Score</div>
          <div class="editor-row">
            <dx-number-box
              class="w3-theme-tertiary"
              width="45%"
              placeholder="from"
              format=",##0"
              [showClearButton]="true"
              [(value)]="simpleFilterValues.attractiveness_scoreFrom"
              (onValueChanged)="
                simpleFilterChanged('attractiveness_score', 'between')
              "
              (onEnterKey)="enterPressed()"
            ></dx-number-box>
            <dx-number-box
              width="45%"
              placeholder="to"
              class="w3-theme-tertiary w3-right"
              format=",##0"
              [showClearButton]="true"
              [(value)]="simpleFilterValues.attractiveness_scoreTo"
              (onValueChanged)="
                simpleFilterChanged('attractiveness_score', 'between')
              "
              (onEnterKey)="enterPressed()"
            ></dx-number-box>
          </div>
        </div>
      </div>
      <div class="w3-col m12 l3 w3-padding">
        <div class="w3-padding w3-theme-tertiary">
          <div class="w3-margin-bottom" translate>Review status</div>
          <dx-select-box
            class="w3-theme-tertiary"
            [showClearButton]="true"
            [items]="[
              'Interesting - added to DealCloud',
              'Interesting - not added to DealCloud',
              'Not interesting',
              'Not reviewed'
            ]"
            [(value)]="simpleFilterValues.review_status"
            (onValueChanged)="simpleFilterChanged('review_status', '=')"
          ></dx-select-box>
        </div>
      </div>
      <div class="w3-col m12 l3 w3-padding">
        <div class="w3-padding w3-theme-tertiary">
          <div class="w3-margin-bottom" translate>Founded</div>
          <div class="editor-row">
            <dx-date-box
              class="w3-theme-tertiary"
              [showClearButton]="true"
              [useMaskBehavior]="true"
              type="date"
              displayFormat="dd/MM/yyyy"
              width="45%"
              pickerType="calendar"
              placeholder="Start Date"
              [(value)]="simpleFilterValues.founded_atFrom"
              (onValueChanged)="simpleFilterChanged('founded_at', 'between')"
              (onEnterKey)="enterPressed()"
            ></dx-date-box>
            <dx-date-box
              class="w3-theme-tertiary w3-right"
              [showClearButton]="true"
              [useMaskBehavior]="true"
              type="date"
              displayFormat="dd/MM/yyyy"
              width="45%"
              pickerType="calendar"
              placeholder="End Date"
              [(value)]="simpleFilterValues.founded_atTo"
              (onValueChanged)="simpleFilterChanged('founded_at', 'between')"
              (onEnterKey)="enterPressed()"
            ></dx-date-box>
          </div>
        </div>
      </div>
    </div>
    <div class="w3-row filter-row">
      <div class="w3-col m12 l3 w3-padding">
        <div class="w3-padding w3-theme-tertiary">
          <div class="w3-margin-bottom" translate>Country</div>
          <dx-tag-box
            *ngIf="countryCodes$ | async as countryCodes"
            class="w3-theme-tertiary"
            [maxDisplayedTags]="5"
            [showClearButton]="true"
            [showSelectionControls]="true"
            [searchEnabled]="true"
            [items]="countryCodes"
            [(value)]="simpleFilterValues.country_code"
            (onValueChanged)="simpleFilterChanged('country_code', 'in', $event)"
          ></dx-tag-box>
        </div>
      </div>
      <div class="w3-col m12 l3 w3-padding">
        <div class="w3-padding w3-theme-tertiary">
          <div class="w3-margin-bottom" translate>City</div>
          <dx-text-box
            class="w3-theme-tertiary"
            [showClearButton]="true"
            [(value)]="simpleFilterValues.city"
            (onValueChanged)="simpleFilterChanged('city', 'contains')"
            (onEnterKey)="enterPressed()"
          ></dx-text-box>
        </div>
      </div>
      <div class="w3-col m12 l3 w3-padding">
        <div class="w3-padding w3-theme-tertiary">
          <div class="w3-margin-bottom" translate>Latest funded year</div>
          <div class="editor-row">
            <dx-date-box
              class="w3-theme-tertiary"
              [showClearButton]="true"
              [useMaskBehavior]="true"
              type="date"
              displayFormat="year"
              zoomLevel="decade"
              [calendarOptions]="{ maxZoomLevel: 'decade' }"
              width="45%"
              pickerType="calendar"
              placeholder="Start Date"
              [(value)]="simpleFilterValues.last_funded_yearFrom"
              (onValueChanged)="
                simpleFilterChanged('last_funded_year', 'between')
              "
              (onEnterKey)="enterPressed()"
            ></dx-date-box>
            <dx-date-box
              class="w3-theme-tertiary w3-right"
              [showClearButton]="true"
              [useMaskBehavior]="true"
              type="date"
              displayFormat="year"
              zoomLevel="decade"
              [calendarOptions]="{ maxZoomLevel: 'decade' }"
              width="45%"
              pickerType="calendar"
              placeholder="End Date"
              [(value)]="simpleFilterValues.last_funded_yearTo"
              (onValueChanged)="
                simpleFilterChanged('last_funded_year', 'between')
              "
              (onEnterKey)="enterPressed()"
            ></dx-date-box>
          </div>
        </div>
      </div>
      <div class="w3-col m12 l3 w3-padding">
        <div class="w3-padding w3-theme-tertiary">
          <div class="w3-margin-bottom" translate>Latest funded amount ($)</div>
          <div class="editor-row">
            <dx-number-box
              class="w3-theme-tertiary"
              width="45%"
              placeholder="from"
              format="$ #,##0.##"
              [showClearButton]="true"
              [(value)]="simpleFilterValues.last_investment_amountFrom"
              (onValueChanged)="
                simpleFilterChanged('last_investment_amount', 'between')
              "
              (onEnterKey)="enterPressed()"
            ></dx-number-box>
            <dx-number-box
              width="45%"
              placeholder="to"
              class="w3-theme-tertiary w3-right"
              format="$ #,##0.##"
              [showClearButton]="true"
              [(value)]="simpleFilterValues.last_investment_amountTo"
              (onValueChanged)="
                simpleFilterChanged('last_investment_amount', 'between')
              "
              (onEnterKey)="enterPressed()"
            ></dx-number-box>
          </div>
        </div>
      </div>
    </div>
    <div class="w3-row filter-row">
      <div class="w3-col m12 l3 w3-padding">
        <div class="w3-padding w3-theme-tertiary">
          <div class="w3-margin-bottom" translate>Employees</div>
          <div class="editor-row">
            <dx-number-box
              class="w3-theme-tertiary"
              width="45%"
              placeholder="from"
              format=",##0"
              [showClearButton]="true"
              [(value)]="simpleFilterValues.last_employee_countFrom"
              (onValueChanged)="
                simpleFilterChanged('last_employee_count', 'between')
              "
              (onEnterKey)="enterPressed()"
            ></dx-number-box>
            <dx-number-box
              width="45%"
              placeholder="to"
              format=",##0"
              class="w3-theme-tertiary w3-right"
              [showClearButton]="true"
              [(value)]="simpleFilterValues.last_employee_countTo"
              (onValueChanged)="
                simpleFilterChanged('last_employee_count', 'between')
              "
              (onEnterKey)="enterPressed()"
            ></dx-number-box>
          </div>
        </div>
      </div>
      <div class="w3-col m12 l3 w3-padding">
        <div class="w3-padding w3-theme-tertiary">
          <div class="w3-margin-bottom" translate>
            Latest employee growth (%)
          </div>
          <div class="editor-row">
            <dx-number-box
              class="w3-theme-tertiary"
              width="45%"
              placeholder="from"
              format=",##0'%'"
              [showClearButton]="true"
              [(value)]="simpleFilterValues.last_employee_growthFrom"
              (onValueChanged)="
                simpleFilterChanged('last_employee_growth', 'between')
              "
              (onEnterKey)="enterPressed()"
            ></dx-number-box>
            <dx-number-box
              width="45%"
              placeholder="to"
              class="w3-theme-tertiary w3-right"
              format=",##0'%'"
              [showClearButton]="true"
              [(value)]="simpleFilterValues.last_employee_growthTo"
              (onValueChanged)="
                simpleFilterChanged('last_employee_growth', 'between')
              "
              (onEnterKey)="enterPressed()"
            ></dx-number-box>
          </div>
        </div>
      </div>
      <div class="w3-col m12 l3 w3-padding">
        <div class="w3-padding w3-theme-tertiary">
          <div class="w3-margin-bottom" translate>Revenue ($)</div>
          <div class="editor-row">
            <dx-number-box
              class="w3-theme-tertiary"
              width="45%"
              placeholder="from"
              format="$ #,##0.##"
              [showClearButton]="true"
              [(value)]="simpleFilterValues.revenueFrom"
              (onValueChanged)="simpleFilterChanged('revenue', 'between')"
              (onEnterKey)="enterPressed()"
            ></dx-number-box>
            <dx-number-box
              width="45%"
              placeholder="to"
              class="w3-theme-tertiary w3-right"
              format="$ #,##0.##"
              [showClearButton]="true"
              [(value)]="simpleFilterValues.revenueTo"
              (onValueChanged)="simpleFilterChanged('revenue', 'between')"
              (onEnterKey)="enterPressed()"
            ></dx-number-box>
          </div>
        </div>
      </div>
      <div class="w3-col m12 l3 w3-padding">
        <div class="w3-padding w3-theme-tertiary">
          <div class="w3-margin-bottom" translate>Latest EBITDA ($)</div>
          <div class="editor-row">
            <dx-number-box
              class="w3-theme-tertiary"
              width="45%"
              placeholder="from"
              format="$ #,##0.##"
              [showClearButton]="true"
              [(value)]="simpleFilterValues.last_ebitdaFrom"
              (onValueChanged)="simpleFilterChanged('last_ebitda', 'between')"
              (onEnterKey)="enterPressed()"
            ></dx-number-box>
            <dx-number-box
              width="45%"
              placeholder="to"
              class="w3-theme-tertiary w3-right"
              format="$ #,##0.##"
              [showClearButton]="true"
              [(value)]="simpleFilterValues.last_ebitdaTo"
              (onValueChanged)="simpleFilterChanged('last_ebitda', 'between')"
              (onEnterKey)="enterPressed()"
            ></dx-number-box>
          </div>
        </div>
      </div>
    </div>
    <div class="w3-row filter-row">
      <div class="w3-col m12 l3 w3-padding">
        <div class="w3-padding w3-theme-tertiary">
          <div class="w3-margin-bottom" translate>Reviewer</div>
          <dx-text-box
            class="w3-theme-tertiary"
            [showClearButton]="true"
            [(value)]="simpleFilterValues.reviewer"
            (onValueChanged)="simpleFilterChanged('reviewer', 'contains')"
            (onEnterKey)="enterPressed()"
          ></dx-text-box>
        </div>
      </div>
    </div>
  </section>
</ng-template>

<ng-template #advancedFilterTemplate>
  <section class="w3-section filter">
    <div class="w3-row filter-row">
      <div class="w3-padding">
        <i class="fa-regular fa-circle-info dx-icon"></i>
        <span>
          All conditions that are grouped in an „AND“ group have to be met by
          the result, while only one condition in an „OR“ group has to be
          fulfilled.
        </span>
      </div>
      <dx-filter-builder
        class="w3-col m12 l6 w3-padding"
        [value]="advancedFilter"
        (onValueChanged)="advancedFilterChanged($event)"
        [groupOperations]="['and', 'or']"
        [fields]="filterFields"
      >
      </dx-filter-builder>
      <div class="w3-col m12 l6 w3-padding">
        <p>{{ filterText }}</p>
        <!-- <pre>{{ advancedFilter | json }}</pre> -->
      </div>
    </div>
  </section>
</ng-template>
