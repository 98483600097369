import { BaseItem, ListItem } from './item.model';

interface MenuItem extends BaseItem {
  routerLink?: string;
}

export interface MenuList<T extends MenuItem = any> extends ListItem {
  limit?: number;
  list: T[];
}
