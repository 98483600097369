import { AlertItem, APIAlerts, toAlert } from '@app/model';
import { BehaviorSubject, merge, of, Subject } from 'rxjs';
import { finalize, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth';
import { environment } from '@app/environment';

@Injectable({ providedIn: 'root' })
export class AlertService {
  private alerts = new BehaviorSubject<AlertItem[]>([]);
  private refreshAlerts = new Subject<void>();
  private companyAlertStatus = new Subject<string>();

  public readonly alerts$ = this.alerts.asObservable();
  public readonly companyAlertStatus$ = this.companyAlertStatus.pipe(
    switchMap((company_id) => this.readCompanyAlertStatus(company_id)),
    map((result) => !!result),
    shareReplay(1)
  );

  constructor(private http: HttpClient, private auth: AuthService) {
    this.companyAlertStatus$.subscribe();
    merge(this.auth.isAuthenticated$, this.refreshAlerts.pipe(map(() => true)))
      .pipe(
        switchMap((load) => (load ? this.readAlerts() : of([] as AlertItem[])))
      )
      .subscribe((alerts) => this.alerts.next(alerts));
  }

  private readAlerts() {
    return this.http
      .post<APIAlerts>(`${environment.apiBase}/alert`, {
        method: 'alert',
      })
      .pipe(
        map(({ companyAlerts, listAlerts }) =>
          [...companyAlerts.map(toAlert), ...listAlerts.map(toAlert)].sort(
            (a, b) => a.alert_date.getTime() - b.alert_date.getTime()
          )
        )
      );
  }

  private readCompanyAlertStatus(company_id: string) {
    return this.http.post<boolean | null>(
      `${environment.apiBase}/companyUser`,
      {
        method: 'companyUser',
        data: {
          requestType: 'read',
          company_id,
        },
      }
    );
  }

  public getCompanyAlert(id: string) {
    this.companyAlertStatus.next(id);
  }

  public updateCompanyAlert(company_id: string, currentStatus: boolean) {
    return this.http
      .post<unknown>(`${environment.apiBase}/companyUser`, {
        method: 'companyUser',
        data: {
          requestType: currentStatus ? 'delete' : 'create',
          company_id,
          ...(currentStatus ? {} : { has_alerts: true }),
        },
      })
      .pipe(finalize(() => this.getCompanyAlert(company_id)));
  }

  public updateAlerts() {
    this.refreshAlerts.next();
  }

  public toggleAlert(alert: AlertItem) {
    return this.http.post(`${environment.apiBase}/${alert.type}`, {
      method: alert.type,
      data: {
        requestType: 'update',
        read: alert.unread,
        ...(alert.type === 'listAlert'
          ? { listAlertId: alert.alert_id }
          : { companyAlertId: alert.alert_id }),
      },
    });
  }
}
