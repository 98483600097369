import { OverwriteConfig } from './overwrite.model';

export const companyOverwrites: OverwriteConfig[] = [
  {
    label: 'City',
    mod_attribute: 'city',
    datatype: 'string',
  },
  {
    label: 'Country',
    mod_attribute: 'country_code',
    datatype: 'string',
  },
  {
    label: 'Domain',
    mod_attribute: 'domain',
    datatype: 'string',
  },
  {
    label: 'Facebook Url',
    mod_attribute: 'facebook_url',
    datatype: 'string',
  },
  {
    label: 'Founded',
    mod_attribute: 'founded_at',
    datatype: 'date',
  },
  {
    label: 'LinkedIn Url',
    mod_attribute: 'linkedin_url',
    datatype: 'string',
  },
  {
    label: 'Long Description',
    mod_attribute: 'long_description',
    datatype: 'string',
  },
  {
    label: 'Name',
    mod_attribute: 'name',
    datatype: 'string',
  },
  {
    label: 'Ownership Type',
    mod_attribute: 'ownership_type',
    datatype: 'string',
  },
  {
    label: 'Short Description',
    mod_attribute: 'short_description',
    datatype: 'string',
  },
  {
    label: 'State Code',
    mod_attribute: 'state_code',
    datatype: 'string',
  },
  {
    label: 'Twitter Url',
    mod_attribute: 'twitter_url',
    datatype: 'string',
  },
  {
    label: 'Type',
    mod_attribute: 'type',
    datatype: 'string',
  },
  {
    label: 'Website Url',
    mod_attribute: 'website_url',
    datatype: 'string',
  },
];

export const aquisitionsOverwrites: OverwriteConfig[] = [
  {
    label: 'Amount',
    mod_attribute: 'amount',
    datatype: 'integer',
  },
  {
    label: 'Currency Code',
    mod_attribute: 'currency_code',
    datatype: 'string',
  },
  {
    label: 'Date',
    mod_attribute: 'date',
    datatype: 'date',
  },
];

export const ipoOverwrites: OverwriteConfig[] = [
  {
    label: 'Date',
    mod_attribute: 'date',
    datatype: 'date',
  },
  {
    label: 'Raised Amount',
    mod_attribute: 'raised_amount',
    datatype: 'integer',
  },
  {
    label: 'Raised Currency Code',
    mod_attribute: 'raised_currency_code',
    datatype: 'string',
  },
  {
    label: 'Share Amount',
    mod_attribute: 'share_amount',
    datatype: 'integer',
  },
  {
    label: 'Share Currency Code',
    mod_attribute: 'share_currency_code',
    datatype: 'string',
  },
  {
    label: 'Stock Exchange Symbol',
    mod_attribute: 'stock_exchange_symbol',
    datatype: 'string',
  },
  {
    label: 'Stock Symbol',
    mod_attribute: 'stock_symbol',
    datatype: 'string',
  },
  {
    label: 'Valuation Amount',
    mod_attribute: 'valuation_amount',
    datatype: 'integer',
  },
  {
    label: 'Valuation Amount Currency Code',
    mod_attribute: 'valuation_currency_code',
    datatype: 'string',
  },
];

export const publicationOverwrites: OverwriteConfig[] = [
  {
    label: 'Date',
    mod_attribute: 'date',
    datatype: 'date',
  },
  {
    label: 'Name',
    mod_attribute: 'name',
    datatype: 'string',
  },
  {
    label: 'Type',
    mod_attribute: 'type',
    datatype: 'string',
  },
  {
    label: 'Url',
    mod_attribute: 'url',
    datatype: 'string',
  },
];

export const investmentOverwrites: OverwriteConfig[] = [
  {
    label: 'Amount',
    mod_attribute: 'amount',
    datatype: 'integer',
  },
  {
    label: 'Currency Code',
    mod_attribute: 'currency_code',
    datatype: 'string',
  },
  {
    label: 'Date',
    mod_attribute: 'date',
    datatype: 'date',
  },
  // {
  //   label: 'Name',
  //   mod_attribute: 'name',
  //   datatype: 'string',
  // },
  // {
  //   label: 'Valuation',
  //   mod_attribute: 'post_money_evaluation',
  //   datatype: 'integer',
  // },
  // {
  //   label: 'Type',
  //   mod_attribute: 'type',
  //   datatype: 'string',
  // },
];

export const revenueOverwrites: OverwriteConfig[] = [
  {
    label: 'CAGR',
    mod_attribute: 'cagr',
    datatype: 'decimal',
  },
  {
    label: 'Currency Code',
    mod_attribute: 'currency_code',
    datatype: 'string',
  },
  {
    label: 'EBITDA',
    mod_attribute: 'ebitda',
    datatype: 'integer',
  },
  {
    label: 'Revenue',
    mod_attribute: 'revenue',
    datatype: 'integer',
  },
  // {
  //   label: 'Revenue per Employee',
  //   mod_attribute: 'revenue_per_employee',
  //   datatype: 'decimal',
  // },
  // {
  //   label: 'Revenue Growth Rate',
  //   mod_attribute: 'revenue_growth_rate',
  //   datatype: 'decimal',
  // },
];

export const employeeOverwrites: OverwriteConfig[] = [
  {
    label: 'Count',
    mod_attribute: 'count',
    datatype: 'integer',
  },
  // {
  //   label: 'Employee Range',
  //   mod_attribute: 'employee_range',
  //   datatype: 'string',
  // },
  {
    label: 'Growth Rate',
    mod_attribute: 'growth_rate',
    datatype: 'decimal',
  },
];

export const valuationOverwrites: OverwriteConfig[] = [
  {
    label: 'Currency Code',
    mod_attribute: 'currency_code',
    datatype: 'string',
  },
  {
    label: 'Valuation',
    mod_attribute: 'valuation',
    datatype: 'integer',
  },
];

export const sectorOverwrite: OverwriteConfig = {
  label: 'Sector',
  mod_attribute: 'subsector_id',
  datatype: 'relation',
};
