import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';

@Component({
  selector: 'app-side-menu-layout',
  templateUrl: './side-menu-layout.component.html',
  styleUrls: ['./side-menu-layout.component.scss'],
})
export class SideMenuLayoutComponent {
  @Input() closeOnOutsideClick = false;
  @Input() openedStateMode = 'shrink';
  @Input() revealMode = 'expand';
  @Input() minSize = 0;
  @Input() shading = false;
  @Input() menuOpened = true;
  @Output() menuOpenedChange = new EventEmitter<boolean>();

  public drawerHeight = 0;

  constructor(@Inject(WINDOW) private windowRef: Window) {
    this.openedStateMode =
      this.windowRef.innerWidth < 993 ? 'overlap' : 'shrink';
    this.closeOnOutsideClick = this.windowRef.innerWidth < 993 ? true : false;
    // 102px for header
    this.drawerHeight = this.windowRef.innerHeight - 102;
    this.menuOpened = this.windowRef.innerWidth >= 993;
  }

  toggleMenu = () => {
    this.menuOpened = !this.menuOpened;
  };

  onOpenedChange(e: boolean) {
    this.menuOpenedChange.emit(e);
  }
}
