import { Options as DataSourceOptions } from 'devextreme/data/data_source';
import {
  Options as CustomStoreOptions,
  ResolvedData,
} from 'devextreme/data/custom_store';
import { Observable } from 'rxjs';
import { LoadOptions } from 'devextreme/data';

export type DataId = number | string | Record<string, number | string>;
export type DataSet = Record<string, unknown>;

export interface DataApiAdapter<
  ReadType extends DataSet = any,
  WriteType extends DataSet = ReadType
> {
  selectAll?: (
    path: string,
    options?: LoadOptions<ReadType>
  ) => Observable<ResolvedData<ReadType>>;
  select?: (path: string, id: DataId) => Observable<ReadType>;
  insert?: (path: string, data: WriteType) => Observable<ReadType>;
  update?: (
    path: string,
    id: DataId,
    data: Partial<WriteType>
  ) => Observable<ReadType>;
  remove?: (path: string, id: DataId) => Observable<void>;
}

export interface DataSourceConfig extends DataSourceOptions {
  path: string;
  key?: string | string[];
  byKey?: (key: DataId) => PromiseLike<DataSet>;
  totalCount?: (options: LoadOptions) => Promise<number>;
  loadMode?: CustomStoreOptions['loadMode'];
  cacheRawData?: CustomStoreOptions['cacheRawData'];
}
