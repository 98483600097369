import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CompanyService } from '@app/core';
import { environment } from '@app/environment';
import {
  companyFields,
  CompanyFilterValues,
  ensureConditionArray,
  getFilterText,
  SubSector,
} from '@app/model';
import { logger } from '@app/shared';
import dxTagBox, { SelectionChangedEvent } from 'devextreme/ui/tag_box';
import { map } from 'rxjs/operators';

type FilterValue = keyof CompanyFilterValues;

const initialSimpleFilterValues: CompanyFilterValues = {
  name: '',
  review_status: '',
  reviewer: '',
  sectors: [],
  last_employee_countFrom: null,
  last_employee_countTo: null,
  investors: '',
  revenueFrom: null,
  revenueTo: null,
  ownership_type: '',
  country_code: [],
  founded_atFrom: '',
  founded_atTo: '',
  last_investment_amountFrom: null,
  last_investment_amountTo: null,
  city: '',
  attractiveness_scoreFrom: null,
  attractiveness_scoreTo: null,
  last_employee_growthFrom: null,
  last_employee_growthTo: null,
  last_funded_yearFrom: '',
  last_funded_yearTo: '',
  last_ebitdaFrom: null,
  last_ebitdaTo: null,
  keywords: [],
};

@Component({
  selector: 'app-companies-filter',
  templateUrl: './companies-filter.component.html',
  styleUrls: ['./companies-filter.component.scss'],
})
export class CompaniesFilterComponent implements OnChanges {
  @Input() filter: unknown[] | null = null;
  @Input() filterUpdateOnChange = false;
  @Input() filterEnabled = true;
  @Output() filterChange = new EventEmitter<unknown[] | null>();
  public advancedFilterVisible = false;

  public simpleFilterValues = { ...initialSimpleFilterValues };
  public simpleFilter: unknown[] = [];
  public advancedFilter: unknown[] = [];
  public filterText = '';
  public filterFields = companyFields;

  public countryCodes$ = this.companyService.countryCodes$;
  public sectors$ = this.companyService.sectors$.pipe(
    map((sectors) =>
      sectors.reduce((acc, cur) => [...acc, ...cur.items], [] as SubSector[])
    )
  );
  public enableAdvancedFilter =
    !!environment.featureFlags &&
    !!environment.featureFlags['ENABLE_ADVANCED_FILTER'];

  constructor(private companyService: CompanyService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filter'] && this.filter !== null) {
      logger.info('companies-filter.ngOnChanges start', {
        filter: this.filter,
        simpleFilter: this.simpleFilter,
        filterValues: this.simpleFilterValues,
      });
      // TODO: implement advanced filter recognition
      this.simpleFilter = ensureConditionArray(this.filter);

      this.simpleFilter.forEach((filter) => {
        const [property, operator, value] = filter as [string, string, unknown];

        if (property === 'keywords') {
          if (
            typeof value === 'string' &&
            this.simpleFilterValues.keywords.join('&') !== value
          ) {
            this.simpleFilterValues.keywords = value.split('&');
          } else if (
            typeof value !== 'string' &&
            this.simpleFilterValues.keywords.length
          ) {
            this.simpleFilterValues.keywords =
              initialSimpleFilterValues.keywords;
          }
        } else if (
          [
            'last_employee_count',
            'revenue',
            'founded_at',
            'last_investment_amount',
            'attractiveness_score',
            'last_employee_growth',
            'last_funded_year',
            'last_ebitda',
          ].includes(property)
        ) {
          if (operator === 'between') {
            this.simpleFilterValues[(property + 'From') as FilterValue] = (
              value as unknown[]
            )[0] as never;
            this.simpleFilterValues[(property + 'To') as FilterValue] = (
              value as unknown[]
            )[1] as never;
          } else {
            this.simpleFilterValues[
              (property + (operator === '>=' ? 'From' : 'To')) as FilterValue
            ] = value as never;
          }
        } else {
          this.simpleFilterValues[property as FilterValue] = (
            (property === 'sectors' || property === 'country_code') &&
            !(value instanceof Array)
              ? [value]
              : value
          ) as never;
        }
      });

      logger.info('companies-filter.ngOnChanges done', {
        filter: this.filter,
        simpleFilter: this.simpleFilter,
        filterValues: this.simpleFilterValues,
      });
    }
  }

  toggleAdvancedFilter = () => {
    this.advancedFilterVisible = !this.advancedFilterVisible;
    this.simpleFilter = [];
    this.simpleFilterValues = { ...initialSimpleFilterValues };
    this.advancedFilter = [];
  };

  updateFilter() {
    logger.info('companies-filter.updateFilter');
    if (this.filterEnabled) {
      const simpleFilter =
        this.simpleFilter.length === 1
          ? (this.simpleFilter[0] as unknown[])
          : this.simpleFilter.reduce(
              (acc: unknown[], cur, idx) => [
                ...acc,
                ...(idx > 0 ? ['and', cur] : [cur]),
              ],
              [] as unknown[]
            );

      this.filterChange.emit(
        this.advancedFilterVisible ? this.advancedFilter || [] : simpleFilter
      );
    }
  }

  advancedFilterChanged(e: { value: unknown[] }) {
    this.advancedFilter = e.value;
    this.filterText = getFilterText(e.value);
    logger.info('advancedFilterChanged -> updateFilter');
    if (this.filterUpdateOnChange) {
      this.updateFilter();
    }
  }

  enterPressed() {
    logger.info('enterPressed -> updateFilter');
    if (!this.filterUpdateOnChange) {
      this.updateFilter();
    }
  }

  simpleFilterChanged(property: string, operator: string, event?: unknown) {
    logger.info(
      'companies-filter.simpleFilterChanged',
      { property, operator },
      event,
      this.filter,
      this.simpleFilter
    );
    const sanitizeValue = (input: string | number | Date, startOf = false) => {
      if (['last_funded_year', 'founded_at'].includes(property)) {
        const date = new Date(input as string | number | Date);
        if (property === 'last_funded_year') {
          if (startOf) {
            date.setDate(1);
            date.setMonth(0);
          } else {
            date.setDate(31);
            date.setMonth(11);
          }
        }

        return date;
      } else {
        return +(input as string | number);
      }
    };

    let filterExpression: null | unknown[] = null;
    if (operator === 'between') {
      const filterFrom = this.simpleFilterValues[
        (property + 'From') as FilterValue
      ] as string | number | Date | null;
      const filterTo = this.simpleFilterValues[
        (property + 'To') as FilterValue
      ] as string | number | Date | null;
      if (filterFrom && filterTo) {
        filterExpression = [
          property,
          operator,
          [sanitizeValue(filterFrom, true), sanitizeValue(filterTo, false)],
        ];
      } else if (filterFrom && !filterTo) {
        filterExpression = [property, '>=', sanitizeValue(filterFrom, true)];
      } else if (!filterFrom && filterTo) {
        filterExpression = [property, '<=', sanitizeValue(filterTo, false)];
      } else {
        filterExpression = null;
      }
    } else if (property === 'keywords') {
      if (this.simpleFilterValues.keywords.length) {
        filterExpression = [
          property,
          'contains',
          this.simpleFilterValues.keywords.join('&'),
        ];
      }
    } else if (
      (property !== 'country_code' && property !== 'sectors') ||
      (property === 'country_code' &&
        this.simpleFilterValues.country_code.length) ||
      (property === 'sectors' && this.simpleFilterValues.sectors.length)
    ) {
      const filterValue = this.simpleFilterValues[property as FilterValue];
      filterExpression = filterValue ? [property, operator, filterValue] : null;
    }

    if ((property === 'country_code' || property === 'sectors') && event) {
      const component = ((event as any).component as dxTagBox).instance();
      const field = component.field() as HTMLInputElement;
      if (field.value) {
        field.value = '';
        component.close();
      }
    }

    if (
      property !== 'keywords' ||
      (event as SelectionChangedEvent).addedItems.length ||
      (event as SelectionChangedEvent).removedItems.length
    ) {
      this.simpleFilter = this.simpleFilter.reduce(
        (acc: unknown[], cur) =>
          cur instanceof Array && cur[0] === property ? acc : [...acc, cur],
        [] as unknown[]
      );

      if (filterExpression !== null) {
        this.simpleFilter = [...this.simpleFilter, filterExpression];
      }
      if (this.filterUpdateOnChange) {
        this.updateFilter();
      }
    }
  }
}
