<ng-content select="[slot=header]"></ng-content>
<dx-drawer
  position="before"
  [closeOnOutsideClick]="closeOnOutsideClick"
  [openedStateMode]="openedStateMode"
  [revealMode]="revealMode"
  [minSize]="minSize"
  [height]="drawerHeight"
  [shading]="shading"
  [(opened)]="menuOpened"
  (openedChange)="onOpenedChange($event)"
>
  <section class="side" *dxTemplate="let data of 'panel'">
    <ng-content select="[slot=side]"></ng-content>
  </section>
  <section class="main">
    <dx-scroll-view>
      <ng-content></ng-content>
      <ng-content select="[slot=footer]"></ng-content>
    </dx-scroll-view>
  </section>
</dx-drawer>
