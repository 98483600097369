import { OverwriteConfig } from './overwrite.model';

type PeopleProperties = {
  first_name: string;
  last_name: string;
  email_address: string;
  job_title: string;
  phone_number: string;
  linked_in_profile_url: string;
};

type PeopleOverwrites = { [Property in keyof PeopleProperties]?: string };

export interface People extends PeopleProperties {
  id: number;
  business_id: string | null;
  employer_id: string;
  overwrite_reference: PeopleOverwrites | null;
}

export const peopleOverwrites: OverwriteConfig[] = [
  { label: 'First name', mod_attribute: 'first_name', datatype: 'string' },
  { label: 'Last name', mod_attribute: 'last_name', datatype: 'string' },
  {
    label: 'Email address',
    mod_attribute: 'email_address',
    datatype: 'string',
  },
  { label: 'Job title', mod_attribute: 'job_title', datatype: 'string' },
  { label: 'Phone number', mod_attribute: 'phone_number', datatype: 'string' },
  {
    label: 'LinkedIn profile URL',
    mod_attribute: 'linked_in_profile_url',
    datatype: 'string',
  },
];
