import { HttpClient } from '@angular/common/http';
import { of, ReplaySubject, throwError } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { DataSourceAdapter } from './data-source.adapter';
import { DataSet, DataApiAdapter, DataSourceConfig, DataId } from './interface';
import { findDataSetIndex } from './util';

export class AssetsApiAdapter<
  ReadType extends DataSet = any,
  WriteType extends DataSet = ReadType
> implements DataApiAdapter<ReadType, WriteType>
{
  private data$ = new ReplaySubject<ReadType[]>(1);
  private keys: string[] = [];

  constructor(
    http: HttpClient,
    fileName: string,
    key: string | string[] = 'id'
  ) {
    this.keys = key instanceof Array ? key : [key];
    http
      .get<ReadType[]>('./assets/' + fileName)
      .subscribe((result) => this.data$.next(result));
  }

  selectAll(path: string, options: unknown) {
    return this.data$.pipe(take(1));
  }

  select(path: string, id: DataId) {
    return this.data$.pipe(
      take(1),
      mergeMap((data) => {
        const index = findDataSetIndex(this.keys, data, id);
        return index !== -1
          ? of(data[index])
          : throwError(() => new Error('item not found'));
      })
    );
  }

  insert(path: string, data: WriteType) {
    return this.data$.pipe(
      take(1),
      map((datasets) => {
        this.data$.next([...datasets, data as unknown as ReadType]);
        return data as unknown as ReadType;
      })
    );
  }

  update(path: string, id: DataId, data: Partial<WriteType>) {
    return this.data$.pipe(
      take(1),
      mergeMap((datasets) => {
        const index = findDataSetIndex(this.keys, datasets, id);
        if (index !== -1) {
          this.data$.next([
            ...datasets.slice(0, index),
            { ...datasets[index], ...data },
            ...datasets.slice(index + 1),
          ]);
          return of({ ...datasets[index], ...data });
        }
        return throwError(() => new Error('item not found'));
      })
    );
  }

  remove(path: string, id: DataId) {
    return this.data$.pipe(
      take(1),
      mergeMap((datasets) => {
        const index = findDataSetIndex(this.keys, datasets, id);
        if (index !== -1) {
          this.data$.next([
            ...datasets.slice(0, index),
            ...datasets.slice(index + 1),
          ]);
          return of();
        }
        return throwError(() => new Error('item not found'));
      })
    );
  }
}

export class AssetsDataSource<T extends DataSet> extends DataSourceAdapter<T> {
  constructor(http: HttpClient, fileName: string, config: DataSourceConfig) {
    super(new AssetsApiAdapter<T>(http, fileName, config.key), config);
  }
}
