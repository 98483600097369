<div class="w3-display-container w3-theme-primary">
  <div class="w3-display-topmiddle w3-padding-48">
    <img src="assets/img/logo_text_white.svg" height="41px" />
  </div>
  <div class="w3-display-middle">
    <section class="w3-content login-form" *ngIf="!showUpdatePassword">
      <form (submit)="login($event, email.value, password.value)">
        <header class="w3-container">
          <h2 class="w3-xlarge">Login</h2>
        </header>
        <section
          class="w3-container w3-margin-bottom w3-margin-left w3-border-theme-secondary w3-bottombar half-line"
        ></section>
        <section class="w3-container">
          <label class=""><b>Email</b></label>
          <input
            #email
            [value]="user"
            name="user-name"
            class="w3-input w3-border w3-light-grey w3-section"
            [ngClass]="{ invalid: !credentialsValid }"
            type="text"
          />

          <label class=""><b>Password</b></label>
          <input
            #password
            [value]="pass"
            name="user-password"
            class="w3-input w3-border w3-light-grey w3-section"
            [ngClass]="{ invalid: !credentialsValid }"
            type="password"
          />
        </section>

        <footer class="w3-container">
          <button class="w3-btn w3-block w3-white w3-section text-bold">
            Sign In
          </button>
        </footer>
      </form>
    </section>
    <section class="w3-content password-form" *ngIf="showUpdatePassword">
      <form
        (submit)="
          updatePassword($event, newPassword.value, verifyPassword.value)
        "
      >
        <header class="w3-container">
          <h2 class="w3-xlarge">Update Password</h2>
        </header>
        <section
          class="w3-container w3-margin-bottom w3-margin-left w3-border-theme-secondary w3-bottombar half-line"
        ></section>
        <section class="w3-container">
          <label class=""><b>New Password</b></label>
          <input
            #newPassword
            name="new-password"
            class="w3-input w3-border w3-light-grey w3-section"
            [ngClass]="{ invalid: !credentialsValid }"
            type="password"
          />

          <label class=""><b>Password</b></label>
          <input
            #verifyPassword
            name="verify-password"
            class="w3-input w3-border w3-light-grey w3-section"
            [ngClass]="{ invalid: !credentialsValid }"
            type="password"
          />
        </section>

        <footer class="w3-container">
          <button class="w3-btn w3-block w3-white w3-section text-bold">
            Update Password
          </button>
        </footer>
      </form>
    </section>
  </div>
</div>
