import * as pako from 'pako';
import { logger } from './log';

export const serializeObject = (
  input: {
    [key: string]: unknown;
  } | null
): string | null => {
  if (input === null) {
    return null;
  }
  try {
    const urlEncodedBase64edCompressedSerializedParams = encodeURIComponent(
      btoa(String.fromCharCode(...pako.deflateRaw(JSON.stringify(input))))
    );
    return urlEncodedBase64edCompressedSerializedParams;
  } catch (e) {
    logger.error(e);
    return null;
  }
};

export const deserializeObject = <T = { [key: string]: unknown }>(
  input: string | null,
  reviver: (key: string, value: any) => any = (_, x) => x
): T | null => {
  if (input === null) {
    return null;
  }
  try {
    const serializedParams = pako.inflateRaw(
      Uint8Array.from(
        Array.from(atob(decodeURIComponent(input))).map((x) => x.charCodeAt(0))
      ),
      { to: 'string' }
    );

    return JSON.parse(serializedParams, reviver);
  } catch (e) {
    logger.error(e);
    return null;
  }
};
