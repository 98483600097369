import DataSource from 'devextreme/data/data_source';
import { DataId } from './interface';

export class Paginator {
  constructor(private ds: DataSource | null) {}

  private hasData() {
    return this.ds === null
      ? Promise.resolve(false)
      : this.ds.isLoaded()
      ? Promise.resolve(true)
      : this.ds.load().then(() => true);
  }

  private getIndex(id: DataId) {
    if (this.ds !== null) {
      return this.ds
        .items()
        .findIndex((x) => x[this.ds?.key() as string] === id);
    }
    return null;
  }

  getInfo(id: DataId) {
    return this.hasData().then((hasData) => {
      if (hasData && this.ds !== null) {
        const index = this.getIndex(id);

        return {
          hasNext:
            !this.ds.isLastPage() ||
            (index !== null && index < this.ds.items().length - 1),
          hasPrev: this.ds.pageIndex() !== 0 || (index !== null && index > 0),
        };
      }
      return { hasNext: false, hasPrev: false };
    });
  }

  getPrev(id: DataId) {
    return this.getInfo(id).then(({ hasPrev }) => {
      if (hasPrev && this.ds !== null) {
        const index = this.getIndex(id);
        if (index === null || index === -1) {
          return null;
        } else if (index > 0) {
          return this.ds.items()[index - 1][this.ds.key() as string] as string;
        } else {
          this.ds.pageIndex(this.ds.pageIndex() - 1);
          return this.ds
            .load()
            .then(
              (items) =>
                items[items.length - 1][this.ds?.key() as string] as string
            );
        }
      }
      return null;
    });
  }

  getNext(id: DataId) {
    return this.getInfo(id).then(({ hasNext }) => {
      if (hasNext && this.ds !== null) {
        const index = this.getIndex(id);
        if (index === null || index === -1) {
          return null;
        } else if (index < this.ds.items().length - 1) {
          return this.ds.items()[index + 1][this.ds.key() as string] as string;
        } else {
          this.ds.pageIndex(this.ds.pageIndex() + 1);
          return this.ds
            .load()
            .then((items) => items[0][this.ds?.key() as string] as string);
        }
      }
      return null;
    });
  }
}
