<div class="w3-bar">
  <a
    class="w3-bar-item w3-button w3-medium text-responsive"
    [routerLink]="segments.length ? '/my-lists/home' : []"
    [ngClass]="{ 'w3-button': segments.length }"
  >
    <i class="fa-regular fa-home"></i>
  </a>
  <ng-container *ngFor="let segment of breadCrumbs">
    <ng-container *ngIf="segment.routerLink && segment.routerLink.length">
      <a
        class="w3-bar-item w3-medium text-responsive"
        [ngClass]="{ 'w3-button': !!segment.routerLink }"
        [routerLink]="segment.routerLink || []"
        translate
      >
        {{ segment.label }}
      </a>
    </ng-container>
    <ng-container *ngIf="!segment.routerLink || !segment.routerLink.length">
      <div class="w3-bar-item w3-medium text-responsive" translate>
        {{ segment.label }}
      </div>
    </ng-container>
  </ng-container>
</div>
