<div class="w3-container w3-padding">
  <header class="w3-border-bottom w3-bottombar w3-border-theme-secondary">
    <div class="w3-bar">
      <div class="w3-bar-item w3-mobile">
        <i class="fa-solid fa-bell-on w3-large w3-margin-right"></i>
        <span class="w3-large upper-case text-bold" translate
          >mylists.label.alerts</span
        >
      </div>
      <div class="w3-bar-item w3-mobile" [ngClass]="{ 'w3-hide': !isMobile }">
        <dx-check-box
          [text]="'Show unread only' | translate"
          [(value)]="filter.showUnread"
          (onValueChanged)="filterAlerts()"
        ></dx-check-box>
      </div>
      <div class="w3-bar-item w3-mobile" [ngClass]="{ 'w3-right': !isMobile }">
        <dx-button
          class="no-transform w3-small"
          [text]="'mylists.action.markAlertsRead' | translate"
          stylingMode="text"
          (onClick)="markAllAsRead()"
        ></dx-button>
      </div>
      <div
        class="w3-bar-item w3-mobile w3-right"
        [ngClass]="{ 'w3-hide': isMobile }"
      >
        <dx-check-box
          [text]="'Show unread only' | translate"
          [(value)]="filter.showUnread"
          (onValueChanged)="filterAlerts()"
        ></dx-check-box>
      </div>
    </div>
  </header>
  <dx-tabs
    [items]="['All', 'Company', 'List']"
    [(selectedIndex)]="filter.selectedTab"
    (onSelectionChanged)="filterAlerts()"
  ></dx-tabs>

  <dx-list
    [scrollingEnabled]="true"
    [items]="visibleAlerts"
    itemTemplate="alertTemplate"
  >
    <div *dxTemplate="let item of 'alertTemplate'">
      <div class="w3-cell-row">
        <div class="w3-cell w3-mobile" (click)="navigateAlert(item)">
          <div class="w3-medium">
            {{ item.name }}
          </div>
          <div class="w3-small" style="white-space: normal">
            <ng-container translate>{{ item.reason }}</ng-container>
          </div>
        </div>

        <div
          class="w3-cell w3-mobile w3-cell-middle w3-small w3-padding"
          style="text-align: right"
        >
          <span (click)="navigateAlert(item)">{{
            item.alert_date | date
          }}</span>
          <dx-check-box
            class="w3-margin-left"
            [value]="item.unread"
            (onValueChanged)="alertToggled(item)"
          ></dx-check-box>
        </div>
      </div>
    </div>
  </dx-list>
</div>
