import { BreadCrumbSegment } from '@app/model';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss'],
})
export class BreadCrumbComponent implements OnChanges {
  @Input() segments: BreadCrumbSegment[] = [];

  public breadCrumbs: BreadCrumbSegment[] = [];

  constructor() {}

  ngOnChanges() {
    this.breadCrumbs = (this.segments || []).reduce(
      (acc, cur) => [...acc, { label: '>' }, cur],
      [] as BreadCrumbSegment[]
    );
  }
}
