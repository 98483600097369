import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core';
import { environment } from '@app/environment';

@Component({
  selector: 'app-unauthenticated',
  templateUrl: './unauthenticated.component.html',
  styleUrls: ['./unauthenticated.component.scss'],
})
export class UnauthenticatedComponent {
  public credentialsValid = true;
  public showUpdatePassword = false;

  public user = environment.creadentials?.usr || '';
  public pass = environment.creadentials?.pwd || '';

  constructor(private auth: AuthService, private router: Router) {}

  login(event: Event, email: string, password: string) {
    event.preventDefault();
    this.auth.login(email, password).subscribe((success) => {
      if (this.auth.needPasswordUpdate) {
        this.showUpdatePassword = this.auth.needPasswordUpdate;
      } else {
        this.credentialsValid = success;
        if (success) {
          this.router.navigateByUrl('/my-lists/home');
        }
      }
    });
  }

  updatePassword(event: Event, newPassword: string, verifyPassword: string) {
    event.preventDefault();
    this.credentialsValid =
      !!newPassword.length && newPassword === verifyPassword;
    if (this.credentialsValid) {
      this.auth.handleNewPassword(newPassword).subscribe((success) => {
        this.credentialsValid = success;
        if (success) {
          this.router.navigateByUrl('/my-lists/home');
        }
      });
    }
  }
}
