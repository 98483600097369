import { DataId, DataSet } from './interface';

export const matchKey = <T extends DataSet>(
  keys: string[],
  item: T,
  id: DataId
) => {
  return keys.every(
    (key) =>
      item[key] ===
      (typeof id === 'number' || typeof id === 'string' ? id : id[key])
  );
};

export const findDataSetIndex = <T extends DataSet>(
  keys: string[],
  datasets: T[],
  id: DataId
) => {
  return datasets.findIndex((item) => matchKey(keys, item, id));
};
