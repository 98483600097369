<app-companies-filter
  [filterEnabled]="!listLoading"
  (filterChange)="filterChanged($event)"
></app-companies-filter>
<dx-data-grid
  [dataSource]="companies || []"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  columnResizingMode="widget"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  [hoverStateEnabled]="true"
  (onRowClick)="gotoCompany($event)"
  (onContextMenuPreparing)="addMenuItems($event)"
  [columns]="columns"
  [(selectedRowKeys)]="selectedCompanyKeys"
>
  <dxo-selection
    mode="multiple"
    showCheckBoxesMode="always"
    selectAllMode="page"
  ></dxo-selection>
  <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
  <dxo-remote-operations
    [filtering]="true"
    [paging]="true"
    [sorting]="true"
  ></dxo-remote-operations>
  <dxo-paging [enabled]="true" [pageSize]="100"></dxo-paging>
  <dxo-pager [visible]="true" [showNavigationButtons]="true"></dxo-pager>
  <dxo-toolbar>
    <dxi-item *ngIf="selectedCompanyKeys.length">
      <dx-drop-down-button
        class="no-transform"
        text="Add to list"
        stylingMode="text"
        [dropDownOptions]="{ width: 230 }"
        [items]="(lists$ | async) || []"
        (onItemClick)="listClicked($event.itemData)"
        displayExpr="name"
        keyExpr="id"
      ></dx-drop-down-button>
    </dxi-item>
    <dxi-item name="columnChooserButton"></dxi-item>
  </dxo-toolbar>

  <div *dxTemplate="let cell of 'cellDescriptionTemplate'">
    <span
      #description
      (mouseenter)="tooltip.visible = true"
      (mouseleave)="tooltip.visible = false"
      [innerHTML]="cell.value"
      style="text-overflow: ellipsis; overflow: hidden; display: block"
    ></span>
    <dx-tooltip
      #tooltip
      [visible]="false"
      maxWidth="50vw"
      [target]="description"
      [position]="{
        my: 'left bottom',
        at: 'left top',
        collision: 'fit flip'
      }"
    >
      <div *dxTemplate="let data of 'content'">
        <p style="white-space: normal; text-align: left">
          {{ cell.value }}
        </p>
      </div>
    </dx-tooltip>
  </div>
  <div *dxTemplate="let cell of 'cellInvestorsTemplate'">
    <a
      class="hover-underline w3-margin-right"
      *ngFor="let investor of cell.value"
      [routerLink]="
        '/companies/' + encodeURIComponent(investor.business_id) + '/overview'
      "
      >{{ investor.name }}</a
    >
  </div>
  <div *dxTemplate="let cell of 'cellIconTemplate'">
    <section>
      <ng-template
        [ngTemplateOutlet]="mediaLink"
        [ngTemplateOutletContext]="{
          url: cell.data.facebook_url,
          icon: 'fa-brands fa-facebook',
          isAlt: cell.isAltRow
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="mediaLink"
        [ngTemplateOutletContext]="{
          url: cell.data.linkedin_url,
          icon: 'fa-brands fa-linkedin',
          isAlt: cell.isAltRow
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="mediaLink"
        [ngTemplateOutletContext]="{
          url: cell.data.twitter_url,
          icon: 'fa-brands fa-twitter',
          isAlt: cell.isAltRow
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="mediaLink"
        [ngTemplateOutletContext]="{
          url: cell.data.website_url,
          icon: 'fa-regular fa-globe',
          isAlt: cell.isAltRow
        }"
      ></ng-template>
    </section>
  </div>
</dx-data-grid>

<ng-template #mediaLink let-icon="icon" let-url="url" let-isAlt="isAlt">
  <ng-container *ngIf="url; then linkItem; else noLinkIcon"></ng-container>
  <ng-template #linkItem>
    <a #linkIcon [href]="url" target="_blank">
      <i
        class="w3-text-theme-secondary margin-horizontal-xs"
        [ngClass]="icon"
      ></i>
    </a>
  </ng-template>
  <ng-template #noLinkIcon>
    <i
      class="margin-horizontal-xs"
      [ngClass]="icon + (isAlt ? ' w3-text-white' : ' w3-text-theme-tertiary')"
    ></i>
  </ng-template>
</ng-template>
