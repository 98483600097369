import notify from 'devextreme/ui/notify';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@app/environment';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from 'rxjs';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  finalize,
  map,
  mergeMap,
  share,
  take,
} from 'rxjs/operators';

import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { NgxPermissionsService, ValidationFn } from 'ngx-permissions';
import { adminPermissions, appPermissions } from './auth.permissions';
import { notificationDuration } from '../config.vars';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private userPool = new CognitoUserPool(environment.userPoolData);
  private isAuthenticated = new ReplaySubject<boolean>(1);
  private userGroups = new BehaviorSubject<string[]>([]);
  private userName = new ReplaySubject<string | null>(1);
  private userId = new ReplaySubject<string | null>(1);
  private pwChangeAttributes: { [prop: string]: string } | null = null;
  private pwChangeUser: CognitoUser | null = null;

  public get needPasswordUpdate() {
    return this.pwChangeAttributes !== null;
  }

  private fetchFreshToken = new Subject<void>();
  private freshToken$: Observable<string | null> = this.fetchFreshToken.pipe(
    concatMap(() =>
      this.getCurrentSession().pipe(
        take(1),
        mergeMap((session) =>
          session && session.isValid()
            ? of(session)
            : this.refreshSession(session)
        ),
        catchError((err) => {
          notify(
            err.message || 'Session Expired',
            'warning',
            notificationDuration.warning
          );
          return of(null);
        })
      )
    ),
    map((session) => (session && session.getIdToken().getJwtToken()) || null),
    share()
  );

  get token$(): Observable<string | null> {
    return new Observable((subscriber) => {
      this.freshToken$.pipe(take(1)).subscribe((x) => {
        subscriber.next(x);
        subscriber.complete();
      });
      this.fetchFreshToken.next();
    });
  }

  get isAuthenticated$() {
    return this.isAuthenticated.pipe(distinctUntilChanged());
  }

  get username$() {
    return this.userName.pipe(distinctUntilChanged());
  }

  get userid$() {
    return this.userId.pipe(distinctUntilChanged());
  }

  get usergroups$() {
    return this.userGroups.pipe(distinctUntilChanged());
  }

  constructor(
    private http: HttpClient,
    private permissionsService: NgxPermissionsService
  ) {
    this.permissionsService.loadPermissions(
      appPermissions,
      this.checkPermission as ValidationFn
    );
    this.getCurrentSession().subscribe(this.importSessionData);
  }

  private getCurrentSession(): Observable<CognitoUserSession | null> {
    return new Observable((subscriber) => {
      const user = this.userPool.getCurrentUser();
      if (user) {
        user.getSession(
          (err: Error | null, session: CognitoUserSession | null) => {
            if (err) {
              subscriber.error(err);
            } else if (session === null) {
              subscriber.next(null);
              subscriber.complete();
            } else {
              subscriber.next(session);
              subscriber.complete();
            }
          }
        );
      } else {
        subscriber.next(null);
        subscriber.complete();
      }
    });
  }

  private refreshSession(
    session: CognitoUserSession | null
  ): Observable<CognitoUserSession | null> {
    return new Observable((subscriber) => {
      const user = this.userPool.getCurrentUser();
      if (user && session) {
        user.refreshSession(
          session.getRefreshToken(),
          (err: Error | null, session: CognitoUserSession | null) => {
            if (err) {
              this.importSessionData(null);
              subscriber.error(err);
            } else {
              this.importSessionData(session);
              subscriber.next(session);
              subscriber.complete();
            }
          }
        );
      } else {
        this.isAuthenticated.next(false);
        subscriber.next(null);
        subscriber.complete();
      }
    });
  }

  private importSessionData = (session: CognitoUserSession | null) => {
    this.isAuthenticated.next(!!session && session.isValid());
    this.userName.next(
      session
        ? session.getIdToken().payload['given_name'] +
            ' ' +
            session.getIdToken().payload['family_name']
        : null
    );
    this.userId.next(session?.getIdToken().payload['sub'] || null);
    this.userGroups.next(
      (session?.getIdToken().payload['cognito:groups'] || []) as string[]
    );
  };

  public checkPermission = (...permissions: string[]) => {
    return permissions.some(
      (permission: string) =>
        this.userGroups.value.some((userPermission) =>
          adminPermissions.includes(userPermission)
        ) || this.userGroups.value.includes(permission)
    );
  };

  changePassword(oldPassword: string, newPassword: string) {
    return this.getCurrentSession().pipe(
      mergeMap((session) => {
        const user = this.userPool.getCurrentUser();
        if (!user || !session) {
          return of(false);
        }
        return new Observable<boolean>((observer) => {
          user.setSignInUserSession(session);
          user.changePassword(oldPassword, newPassword, (err) => {
            if (err) {
              notify(
                err.message || 'Password change failed',
                'error',
                notificationDuration.error
              );
              observer.next(false);
            } else {
              notify(
                'Password change successful',
                'success',
                notificationDuration.success
              );
              observer.next(true);
            }

            observer.complete();
          });
        });
      })
    );
  }

  handleNewPassword(newPassword: string) {
    return new Observable<boolean>((observer) => {
      if (this.pwChangeUser !== null) {
        this.pwChangeUser.completeNewPasswordChallenge(
          newPassword,
          this.pwChangeAttributes,
          {
            onSuccess: (session) => {
              this.importSessionData(session);
              notify(
                'Password updated',
                'success',
                notificationDuration.success
              );
              this.pwChangeAttributes = null;
              this.pwChangeUser = null;
              observer.next(session && session.isValid());
              observer.complete();
            },
            onFailure: (err) => {
              notify(
                err.message || 'Password update failed',
                'error',
                notificationDuration.error
              );
              this.isAuthenticated.next(false);
              this.userGroups.next([]);
              observer.next(false);
              observer.complete();
            },
          }
        );
      }
    });
  }

  logout() {
    return this.getCurrentSession().pipe(
      mergeMap((session) => {
        const user = this.userPool.getCurrentUser();
        if (!session || !user) {
          return of(false);
        }
        return new Observable<boolean>((subscriber) => {
          user.setSignInUserSession(session);
          user.globalSignOut({
            onSuccess: () => {
              this.isAuthenticated.next(false);
              this.userGroups.next([]);
              subscriber.next(true);
              subscriber.complete();
            },
            onFailure: (err) => {
              notify(
                err.message || 'Logout failed',
                'error',
                notificationDuration.error
              );
              subscriber.error(err);
            },
          });
        });
      })
    );
  }

  login(Username: string, Password: string) {
    return new Observable<boolean>((observer) => {
      const authenticationDetails = new AuthenticationDetails({
        Username,
        Password,
      });

      const user = new CognitoUser({
        Username,
        Pool: this.userPool,
      });

      user.authenticateUser(authenticationDetails, {
        onSuccess: (session) => {
          this.importSessionData(session);
          this.http
            .post(`${environment.apiBase}/sync`, { method: 'sync' })
            .subscribe();
          observer.next(session && session.isValid());
          observer.complete();
        },
        onFailure: (err) => {
          notify(
            err.message || 'Login failed',
            'error',
            notificationDuration.error
          );
          this.isAuthenticated.next(false);
          this.userGroups.next([]);
          observer.next(false);
          observer.complete();
        },
        newPasswordRequired: (userAttributes, requiredAttributes: string[]) => {
          this.pwChangeAttributes =
            requiredAttributes instanceof Array
              ? requiredAttributes.reduce(
                  (acc, cur) => ({ ...acc, [cur]: userAttributes[cur] }),
                  {}
                )
              : {};
          this.pwChangeUser = user;
          observer.next(false);
          observer.complete();
        },
      });
    });
  }
}
