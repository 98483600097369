import { Injectable } from '@angular/core';
import DevicesObject from 'devextreme/core/devices';
import { logger } from '@app/shared';

@Injectable({ providedIn: 'root' })
export class DeviceService {
  constructor() {
    logger.log(DevicesObject.current());
  }

  get isPhone() {
    return !!DevicesObject.current().phone;
  }

  get isTablet() {
    return !!DevicesObject.current().tablet;
  }

  get isMobile() {
    return this.isPhone || this.isTablet;
  }

  get isIOS() {
    return (
      !!DevicesObject.current().ios ||
      DevicesObject.current().platform === 'ios'
    );
  }

  get isAndroid() {
    return (
      !!DevicesObject.current().android ||
      DevicesObject.current().platform === 'android'
    );
  }
}
