import { HttpClient } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import {
  IModuleTranslationOptions,
  ModuleTranslateLoader,
} from '@larscom/ngx-translate-module-loader';

interface ModuleLoaderConfig extends IModuleTranslationOptions {
  pathTemplate?: string;
  baseTranslateUrl?: string;
}

interface FileLoaderConfig extends Omit<ModuleLoaderConfig, 'modules'> {
  files: string[];
}

interface BaseLoaderConfig extends Omit<ModuleLoaderConfig, 'modules'> {
  baseFileTemplate?: string;
}

export type LanguageLoaderConfig =
  | ModuleLoaderConfig
  | FileLoaderConfig
  | BaseLoaderConfig;

export const LANGUAGE_LOADER_CONFIG_TOKEN =
  new InjectionToken<LanguageLoaderConfig>('Language Loader Config');

export const LANGUAGE_FILES_TOKEN = new InjectionToken<string[]>(
  'Language File Names'
);

export function createTranslateLoader(
  http: HttpClient,
  asd: LanguageLoaderConfig
) {
  const {
    pathTemplate = '{baseTranslateUrl}/{language}/{moduleName}',
    baseTranslateUrl = './assets/i18n',
    ...config
  } = asd || {};

  const modules =
    (config as ModuleLoaderConfig).modules instanceof Array
      ? (config as ModuleLoaderConfig).modules.map((item) => ({
          pathTemplate,
          ...item,
        }))
      : (config as FileLoaderConfig).files instanceof Array
      ? (config as FileLoaderConfig).files.map((moduleName) => ({
          moduleName,
          baseTranslateUrl,
          pathTemplate,
        }))
      : [
          {
            moduleName: '',
            baseTranslateUrl,
            pathTemplate:
              (config as BaseLoaderConfig).baseFileTemplate ||
              '{baseTranslateUrl}/{language}',
          },
        ];

  const options: IModuleTranslationOptions = {
    ...config,
    lowercaseNamespace: true,
    modules,
  };

  return new ModuleTranslateLoader(http, options);
}
