import { DataSourceAdapter } from './data-source.adapter';
import { of, throwError } from 'rxjs';
import { DataApiAdapter, DataId, DataSet, DataSourceConfig } from './interface';
import { findDataSetIndex } from './util';

export class MockApiAdapter<T extends DataSet> implements DataApiAdapter<T> {
  private keys: string[] = [];

  constructor(private data: T[], key: string | string[] = 'id') {
    this.keys = key instanceof Array ? key : [key];
  }

  selectAll(path: string, options: unknown) {
    return of(this.data);
  }

  select(path: string, id: DataId) {
    const index = findDataSetIndex(this.keys, this.data, id);
    return index !== -1
      ? of(this.data[index])
      : throwError(() => new Error('item not found'));
  }

  insert(path: string, data: T) {
    this.data.push(data);
    return of(data);
  }

  update(path: string, id: DataId, data: Partial<T>) {
    const index = findDataSetIndex(this.keys, this.data, id);
    if (index !== -1) {
      this.data[index] = { ...this.data[index], ...data };
      return of(this.data[index]);
    }
    return throwError(() => new Error('item not found'));
  }

  remove(path: string, id: DataId) {
    const index = findDataSetIndex(this.keys, this.data, id);
    if (index !== -1) {
      this.data = [...this.data.slice(0, index), ...this.data.slice(index + 1)];
    }
    return of();
  }
}

export class MockDataSource<T extends DataSet> extends DataSourceAdapter<T> {
  constructor(data: T[], config: DataSourceConfig) {
    super(new MockApiAdapter(data, config.key), config);
  }
}
