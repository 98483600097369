import { CompanyService, notificationDuration } from '@app/core';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  Company,
  companyColumns,
  companyFields,
  isCondition,
  ListType,
} from '@app/model';
import { map } from 'rxjs/operators';
import notify from 'devextreme/ui/notify';
import { TranslateService } from '@ngx-translate/core';
import { ContextMenuPreparingEvent } from 'devextreme/ui/data_grid';
import DataSource from 'devextreme/data/data_source';

declare const window: any;

@Component({
  selector: 'app-companies-search',
  templateUrl: './companies-search.component.html',
  styleUrls: ['./companies-search.component.scss'],
})
export class CompaniesSearchComponent {
  @Output() companySelected = new EventEmitter<string>();
  @Output() isSearching = new EventEmitter<boolean>();
  public filterFields = companyFields;
  public columns = companyColumns;
  public filter: unknown[] | null = null;
  public companies: DataSource | null = null;
  public lists$ = this.companyService.manualLists$;

  public selectedCompanyKeys: any[] = [];
  public listLoading = false;
  public encodeURIComponent = encodeURIComponent;

  constructor(
    private companyService: CompanyService,
    private translateService: TranslateService
  ) {}

  filterChanged(filter: unknown[] | null) {
    if (this.companies === null) {
      this.companies = this.companyService.getCompaniesAsDataSource();
      this.companies.on('loadingChanged', (isLoading: boolean) =>
        this.isSearching.emit((this.listLoading = isLoading))
      );
    }
    this.companies.filter(filter);
    this.companies.load();
  }

  listClicked(list: ListType) {
    this.companyService
      .addCompanyToList(this.selectedCompanyKeys, list.id)
      .subscribe({
        next: () =>
          notify('Companies added', 'success', notificationDuration.success),
        error: (e) => notify(e.message, 'error', notificationDuration.error),
      });
  }

  gotoCompany(e: { data: Company }) {
    this.companySelected.emit(e.data.business_id);
  }

  addMenuItems(e: ContextMenuPreparingEvent) {
    if (e.target == 'content' && e.row) {
      if (!e.items) {
        e.items = [];
      }
      e.items.push({
        text: this.translateService.instant('common.label.openInNewTab'),
        onItemClick: () => {
          window.open(
            `#/companies/${e.row?.data.business_id}/overview`,
            '_blank'
          );
        },
      });
    }
  }
}
