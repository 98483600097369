import { environment } from '@app/environment';

const logToConsole = (method: keyof Console, ...args: any[]) => {
  if (typeof console[method] === 'function' && !environment.production) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    console[method](...args);
  }
};

export const logger: {
  log: Console['log'];
  info: Console['info'];
  warn: Console['warn'];
  error: Console['error'];
} = {
  log: (...args) => {
    logToConsole('log', ...args);
  },
  info: (...args) => {
    logToConsole('info', ...args);
  },
  warn: (...args) => {
    logToConsole('warn', ...args);
  },
  error: (...args) => {
    logToConsole('error', ...args);
  },
};
